export const html1 = `
<p>&nbsp;</p>
<!-- [if (mso 16)]>
<style type="text/css">
a {text-decoration: none;}
</style>
<![endif]-->
<p>&nbsp;</p>
<!-- [if gte mso 9]><style>sup { font-size: 100% !important; }</style><![endif]-->
<p>&nbsp;</p>
<!-- [if gte mso 9]>
<xml>
<o:OfficeDocumentSettings>
<o:AllowPNG></o:AllowPNG>
<o:PixelsPerInch>96</o:PixelsPerInch>
</o:OfficeDocumentSettings>
</xml>
<![endif]-->
<div class="es-wrapper-color"><!-- [if gte mso 9]>
<v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
<v:fill type="tile" color="#efefef"></v:fill>
</v:background>
<![endif]-->
<table class="es-wrapper" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px; padding: 0; margin: 0; width: 100%; height: 100%; background-repeat: repeat; background-position: center top; background-color: white;" width="100%" cellspacing="0" cellpadding="0">
<tbody>
<tr style="border-collapse: collapse;">
<td style="padding: 0; margin: 0;" valign="top">
<table class="es-header" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px; table-layout: fixed !important; width: 100%; background-color: transparent; background-repeat: repeat; background-position: center top;" cellspacing="0" cellpadding="0" align="left">
<tbody>
<tr style="border-collapse: collapse;">
<td style="padding: 0; margin: 0; background-color: #ffffff;" align="center" bgcolor="#ffffff">
<table class="es-header-body" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px; background-color: #ffffff; width: 600px;" cellspacing="0" cellpadding="0" align="left" bgcolor="#ffffff">
<tbody>
<tr style="border-collapse: collapse;">
<td style="padding: 10px 0px 2px 2px; margin: 0;" align="left"><!-- [if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr><td style="width:181px" valign="top"><![endif]--><!-- [if mso]></td></tr></table><![endif]--></td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<table class="es-content" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px; table-layout: fixed !important; width: 100%;" cellspacing="0" cellpadding="0" align="center">
<tbody>
<tr style="border-collapse: collapse;">
<td style="padding: 0; margin: 0; background-color: #ffffff; padding-bottom: 10px;" align="center" bgcolor="#ffffff">
<table class="es-content-body" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px; background-color: #ffffff; width: 600px;" cellspacing="0" cellpadding="0" align="center" bgcolor="#ffffff">
<tbody>
<tr style="border-collapse: collapse;">
<td style="padding: 20px; margin: 0;" align="center">
<table style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px;" width="90%" cellspacing="0" cellpadding="0">
<tbody>
<tr style="border-collapse: collapse;">
<td class="es-m-p0r" style="padding: 0; margin: 0; width: 580px;" align="center" valign="top">
<table style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px;" role="presentation" width="100%" cellspacing="0" cellpadding="0">
<tbody>
<tr style="border-collapse: collapse;">
<td class="es-m-txt-l" style="padding: 0; margin: 0; padding-bottom: 10px;" align="left">
<h1 style="margin: 0; line-height: 36px; mso-line-height-rule: exactly; font-family: arial, 'helvetica neue', helvetica, sans-serif; font-size: 30px; font-style: normal; font-weight: normal; color: #666666; word-break: break-word;">Thank you for choosing ACERTUS for your vehicle delivery!</h1>
<p style="word-break: break-word; color: #3c3c3c; margin-bottom: 0; font-size: 14px; text-align: left;">We are constantly innovating and striving to provide you with the best possible experience.</p>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr style="border-collapse: collapse;">
<td class="esdev-adapt-off" style="padding: 0; margin: 0; padding-left: 20px; padding-right: 20px; padding-top: 20px;" align="left">
<table class="esdev-mso-table" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px; background-color: #efefef; width: 600px;" cellspacing="0" cellpadding="0" align="center" bgcolor="#efefef">
<tbody>
<tr>
<td style="padding-top: 10px; padding-left: 12px; margin: 0; width: 270px;" colspan="2" align="left" valign="top">&nbsp;</td>
</tr>
<tr style="border-collapse: collapse;">
<td style="padding: 0; margin: 0; padding-top: 20px; padding-left: 20px; padding-right: 20px;" align="left">
<table class="es-left" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px; float: left;" cellspacing="0" cellpadding="0" align="left">
<tbody>
<tr style="border-collapse: collapse;">
<td style="padding: 0; margin: 0; width: 230px;" align="center" valign="top">
<table style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px; background-position: center top;" role="presentation" width="100%" cellspacing="0" cellpadding="0">
<tbody>
<tr style="border-collapse: collapse;">
<td style="padding: 0; margin: 0; padding-bottom: 4px;" align="left">
<div class="dyField"></div>
<h4 style="margin: 0; line-height: 25px; mso-line-height-rule: exactly; font-family: arial, 'helvetica neue', helvetica, sans-serif; color: #333333; font-size: 16px;">VIN:</h4>
<div class="myTag"></div>
<p style="margin: 0; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; font-family: arial, 'helvetica neue', helvetica, sans-serif; line-height: 21px; color: #666666; font-size: 12px;">&nbsp;</p>
</td>
</tr>
<tr style="border-collapse: collapse;">
    <td style="padding: 0; margin: 0; padding-top: 1px;" align="left">
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    </td>
    </tr>
<tr style="border-collapse: collapse;">
<td style="padding: 0; margin: 0; padding-top: 5px; padding-bottom: 10px;" align="left">
<div class="dyField"></div>
<h4 style="margin: 0; line-height: 25px; mso-line-height-rule: exactly; font-family: arial, 'helvetica neue', helvetica, sans-serif; color: #333333; font-size: 16px;">Origin:</h4>
<div class="myTag"></div>
<p style="margin: 0; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; font-family: arial, 'helvetica neue', helvetica, sans-serif; line-height: 21px; color: #666666; font-size: 12px; word-break: break-all;"><br /><br /></p>
</td>
</tr>

</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<table class="es-right" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px; float: right; margin-bottom: 20px;" cellspacing="0" cellpadding="0" align="right">
<tbody>
<tr style="border-collapse: collapse;">
<td style="padding: 0; margin: 0; width: 270px; margin-bottom: 20px;" align="left">
<table style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px;" role="presentation" width="100%" cellspacing="0" cellpadding="0">
<tbody>
<tr style="border-collapse: collapse;">
<td style="padding: 0; margin: 0; padding-bottom: 20px;" align="left">
<div class="dyField"></div>
<h2 style="margin: 0; line-height: 25px; mso-line-height-rule: exactly; font-family: arial, 'helvetica neue', helvetica, sans-serif; color: darkorange; font-size: 21px;">Status:</h2>
<div class="myTag"></div>
</td>
</tr>
<tr style="border-collapse: collapse;">
<td style="padding: 0; margin: 0; padding-top: 5px;" align="left">
<div>&nbsp;</div>
<div>&nbsp;</div>
</td>
</tr>
<tr style="border-collapse: collapse;">
    <td style="padding: 0; margin: 0; padding-top: 5px; padding-bottom: 5px;" align="left">
    <div class="dyField"></div>
    <h4 style="margin: 0; line-height: 25px; mso-line-height-rule: exactly; font-family: arial, 'helvetica neue', helvetica, sans-serif; color: #333333; font-size: 16px;">Destination:</h4>
	<div class="myTag"></div>
    <p style="margin: 0; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; font-family: arial, 'helvetica neue', helvetica, sans-serif; line-height: 21px; color: #666666; font-size: 12px; word-break: break-all;"><br /><br /></p>
    </td>
    </tr>
<!-- <tr style="border-collapse: collapse;">
<td style="padding-top: 10px; margin: 0;" align="left"><a style="-webkit-text-size-adjust: none; -ms-text-size-adjust: none; mso-line-height-rule: exactly; text-decoration: none; color: white; font-size: 1rem; display: inline-block; font-weight: 400; text-align: center; -moz-user-select: none; -ms-user-select: none; border: 1px solid transparent; padding: .375rem .5rem; line-height: 1; border-radius: .25rem; background: darkorange;" href="{{order_status_url}}" target="_blank" rel="noopener"> Track My Vehicle </a></td> -->
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
<td>&nbsp;</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<table class="es-content" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px; table-layout: fixed !important; width: 100%;" cellspacing="0" cellpadding="0" align="center">
<tbody>
<tr style="border-collapse: collapse;">
<td style="padding: 0; margin: 0; background-color: #ffffff;" align="center" bgcolor="#ffffff">
<table class="es-content-body" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px; background-color: #ffffff; width: 600px;" cellspacing="0" cellpadding="0" align="center" bgcolor="#ffffff">
<tbody>
<tr style="border-collapse: collapse;">
<td style="padding: 0; margin: 0; padding-left: 20px; padding-top: 15px;" align="left">
<table style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px;" width="100%" cellspacing="0" cellpadding="0">
<tbody>
<tr style="border-collapse: collapse;">
<td class="es-m-p0r" style="padding: 0; margin: 0; width: 580px;" align="center" valign="top">
<table style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-collapse: collapse; border-spacing: 0px;" role="presentation" width="100%" cellspacing="0" cellpadding="0">
<tbody>
<tr style="border-collapse: collapse;">
<td class="es-m-txt-l" style="padding: 0; margin: 0; padding-bottom: 10px;" align="center">
<h4 style="margin: 0; line-height: 20px; mso-line-height-rule: exactly; font-family: arial, 'helvetica neue', helvetica, sans-serif; font-size: 18px; font-style: normal; font-weight: 500; color: #666666;">Need Assistance</h4>
<p style="word-break: break-word; color: #3c3c3c; margin-bottom: 0; font-size: 14px;">Call 314-334-3400 to speak with our representative.</p>
<p style="word-break: break-word; color: #3c3c3c; margin-bottom: 0; font-size: 14px;"><a id="unsubscribe-btn" style="color: orange; cursor: pointer;" title="Click to un-subscribe from email." href="{{unsubscribe_url}}" target="_blank" rel="noopener">Click here</a> to Unsubscribe from Emails.</p>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</div>
`;
