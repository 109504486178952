import axios from "axios";

export default function useGetAllTemplate(){

    const fetchAllTemplate = async (notifChannel) => {
            let res = await axios.get(`${process.env.REACT_APP_BASE_URL}get-templates?search=${notifChannel}`);
console.log("response",res)
          let data = await res.data;
          console.log("view", data)
          return data;
    }

    return {fetchAllTemplate}
}