
export const CONSTANTS = {
    TEMPLATE_NOT_FOUND : 'No Template found with this name',
    ERROR_IN_FETCHING : 'Error in fetching Template',
    ERROR_IN_UPDATING : 'Error in updating Template',
    TEMPLATE_NAME : 'Enter template name',
    TEMPLATE_UPDATED : 'Template Updated',
    DUPLICATE_VARIABLE_TITLE : 'Duplicate Field Name',
    DUPLICATE_VARIABLE_MESSAGE : 'Variable with this name already exist!\n Try with a new name',
    SAME_TEMPLATE_NAME : 'Template with this name already exist',
    ERROR_IN_CREATING : 'Error in Creating the template!'
}