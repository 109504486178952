import React from "react";
import { Button } from "antd";
import { useCopyToClipboard } from "react-use";

interface CopyButtonProps {
  fieldCode: string;
  parentCallback:any;
  visible:boolean;
}

let copiedCode:string[] = [];

/* This copy button will be called to SearchField and loops for the any number of fields available  */

const CopyButton: React.FC<CopyButtonProps> = (props) => {
  const [state, copyToClipboard] = useCopyToClipboard();
  const [buttonText, setButtonText] = React.useState("Copy Variable");
  return (
    <>
      <Button
      className="copyBtn"
      style={{display:props.visible?'block':'none'}}
        onClick={() => {
          copyToClipboard(props.fieldCode);

          console.log(state);
          setButtonText("Copied");
          setTimeout(() => {
            setButtonText("Copy Variable");
          }, 5000);
          copiedCode.push(props.fieldCode);
        props.parentCallback(copiedCode);
        }}
      >
        {buttonText}
      </Button>
    </>
  );
};

export default CopyButton;
