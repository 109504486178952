/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Input,
  message,
  Modal,
  Radio,
  Spin,
  Tooltip,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../constants/constants";
import useTriggerAPI from "../hooks/useTriggerAPI";
import MyHeader from "../components/Header";
// import no_data_img from "../assets/no_data_1.png";
import sortFetchedList from "../Utils/sortFetchedList";
import "./Styles/ViewTemplate.css";
import { Detector } from "react-detect-offline";
import { EyeFilled, LoadingOutlined } from "@ant-design/icons";
import useGetAllTemplate from "../hooks/useGetAllTemplate";
import { css} from '@emotion/react'





export default function ViewTemplate() {
  const nav = useNavigate();
  console.log(window.navigator.onLine);
  useEffect(() => {

    getAllTemplates();
  }, []);

  useEffect(() => {
    if (window.localStorage.getItem("isAuthenticated") === "false") {
      nav("/");
    }
  }, []);

  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        setIsViewModalVisible(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const [tempID, setTempID] = useState("");

  const [notifChannel, setNotifChannel] = useState("EMAIL");
  const [fetchedTemplatesList, setFetchedTemplatesList] = useState<any[]>([]);
  const [fetchedAllTemplatesList, setFetchedAllTemplatesList] = useState<any[]>(
    []
  );

  const [pageNumber, setPageNumber] = useState(1);
  const [paginationVisible, setPaginationVisible] = useState(false);

  const [currentSelectedTemplateID, setcurrentSelectedTemplateID] =
    useState<string>("");
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [displayNoDataImage, setDisplayNoDataImage] = useState(true);

  const { fetchData } = useTriggerAPI();
  const { fetchAllTemplate } = useGetAllTemplate();

  const [template, setTemplate] = useState("");
  const [loading, setLoading] = useState(false);

  const [viewAllModalVisible, setViewAllModalVisible] = useState(false);

  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  const header = css({ backgroundColor: 'rgb(100, 108, 140)', color: 'white', margin: '50px'});
  // const [page, setPage] = useState(1);

  const columns = [
    {
      title: 'Template Name',
      dataIndex: 'template_id',
      key: 'template_id',
      align: 'left' as 'left',
      className: 'ant-table-thead ant-table-cell column',
      
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
      width: 150,
      align: 'center' as 'center',
      className: 'column',
    },
    {
      title: 'Latest Created/Updated',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center' as 'center',
      className: 'column',
      render: (obj) => {
        return(getDateAndTime(obj))
      }
    },
    {
      title: 'View',
      key: 'operation',
      width: 150,
      align: 'center' as 'center',
      className: 'column',
      render: (obj) =>{
        return(
          <Tooltip title="View Template">
          <EyeFilled className="modalEditNew"  onClick={() => {
                            onViewBtnClick(obj);
                          }} style={{ fontSize: "large" }} />
          </Tooltip>
        )
      } 
    },
  ];

  const getDateAndTime = (timestamp: string) => {
    let date = new Date(timestamp);
    return date.toLocaleString('en-US');
  };

  const data = fetchedTemplatesList.map((row, id)=> ({
    template: row.template,
    template_id: row.template_id,
    version: row.version,
    created_at: getDateAndTime(row.created_at),
    key: id,
    children: row.children,
    notification_channel: row.notification_channel,
  }))

  

  // to display warning even after the page is refreshed
  if (!isOnline) {
    warning(
      "No Internet Connection",
      "It seems you have lost your internet connection...Please fix and retry!",
      false,
      false
    );
  } else {
    Modal.destroyAll();
  }

  const onRadioChange = (e) => {
    setNotifChannel(e.target.value);
  };

  async function getAllTemplates(){
    try {
    let data = await fetchAllTemplate(notifChannel);
    data = data.sort(sortByTimeStamp);

    setDisplayNoDataImage(false);
    // setPaginationVisible(true);
    // setFetchedAllTemplatesList(data);
    setFetchedTemplatesList(data);
    setLoading(false);
  } catch (error) {
    message.error(CONSTANTS.ERROR_IN_FETCHING);
    
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }
  }

  let payload = {
    data: {
      template_id: tempID,
      notification_channel: notifChannel,
      request_type: "list_by_templateid",
    },
  };

  const handleSearch = async () => {
    message.config({ maxCount: 0, duration: 1 });
    setLoading(true);
    setPageNumber(1);
    setTemplate("");
    setFetchedTemplatesList([]);
    if (tempID) {
      setPaginationVisible(false);
      payload["data"]["template_id"] = tempID;
      payload["data"]["notification_channel"] = notifChannel;

      try {
        let data = await fetchData(payload);

        if (data.length) {
          setLoading(false);
          setDisplayNoDataImage(false);
          setFetchedTemplatesList(sortFetchedList(data));
          setLoading(false);
        } else {
          setLoading(false);
          message.error(CONSTANTS.TEMPLATE_NOT_FOUND);
          setDisplayNoDataImage(false);
        }
      } catch (error) {
        message.error(CONSTANTS.ERROR_IN_FETCHING);
        setLoading(false);
      }
    } else {
    setIsSearchClicked(true);
      getAllTemplates();
    }
  };

  function handleEnterPress(e) {
    if (e.key === "Enter") {
      handleSearch();
    }
  }

  function sortByTimeStamp(x, y) {
    let date1 = new Date(x.created_at);
    let date2 = new Date(y.created_at);
    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
  }

  function disableAllElements() {
    let doc = document.getElementById("master");

    let tags = ["select", "radio", "input", "textarea", "button", "a"];
    for (let index = 0; index < tags.length; index++) {
      const element = doc?.getElementsByTagName(tags[index]) ?? [];

      for (let j = 0; j < element.length; j++) {
        const elementToBeDisabled = element[j] as any;

        elementToBeDisabled.disabled = true;
        elementToBeDisabled.style.cursor = "default";
        elementToBeDisabled.style.pointerEvents = "none";
      }
    }
  }
  if (isViewModalVisible) {
    disableAllElements();
  }
  const onViewBtnClick = async (template) => {
    let template__ ;
    // if(paginationVisible){
      setViewAllModalVisible(true);
      payload['data']['template_id'] = template.template_id 
      payload['data']['notification_channel'] = template.notification_channel
      try {
        let templateData = await fetchData(payload);
        templateData.map((data)=>{
          if(data.version === template.version){
            template__ = data.template;
            setViewAllModalVisible(false);
          }
        })
        
       
      } catch (error) {
        console.log(error);
        
      }
    // }else{
    //   template__ = template.template
    // }
    setTemplate(template__);
    setIsViewModalVisible(true);

    setcurrentSelectedTemplateID(template.tempID);
  };

  function handlePaginationChange(page, pageSize) {

    setPageNumber(page);
    if (page === 1) {
      setFetchedTemplatesList(fetchedAllTemplatesList.slice(0, 6));
    } else {
      setFetchedTemplatesList(
        fetchedAllTemplatesList.slice((page - 1) * pageSize, page * pageSize)
      );
    }
  }



  function goToHomePage() {
    nav("/home");
  }

  function warning(
    title: string,
    content: string,
    okBtnVisible: boolean,
    cancelBtnVisible: boolean
  ) {
    Modal.confirm({
      title: title,
      content: content,
      okButtonProps: {
        style: { visibility: `${okBtnVisible ? "visible" : "hidden"}` },
      },
      cancelButtonProps: {
        style: { visibility: `${cancelBtnVisible ? "visible" : "hidden"}` },
      },
      cancelText: "Go to Home Page",
      closable: false,
      keyboard: false,
      onCancel: goToHomePage,
    });
  }

  function handleInternetChange(isOnline_) {
    setIsOnline(isOnline_);
  }

  return (
    <>
      <MyHeader pageTitle="View Template" homebtnVisible />

      <h3 className="tempHeading">Search for Template below:</h3>
      <div className="mainContent">
        <div className="searchPart">
          <div className="cardContainer">
            <Input
              style={{ borderColor: "black" }}
              placeholder="Enter Template Name"
              value={tempID}
              onKeyDown={handleEnterPress}
              onChange={(e) => {
                setTempID(e.target.value);
              }}
            />
            <Radio.Group onChange={onRadioChange} defaultValue={"EMAIL"}>
              <Radio value={"EMAIL"}>Email</Radio>
              <Radio value={"SMS"}>Text Notification</Radio>
            </Radio.Group>

            <Button
              type="primary"
              loading={loading}
              block
              onClick={handleSearch}
            >
              {tempID ? "Search" : "View all Templates"}
            </Button>
          </div>
        </div>
        <div
          className="noDataImage"
          style={{ display: displayNoDataImage ? "block" : "none" }}
        >
        <div className="example">
         <Spin size="large"/>
         </div>
        </div>

        <div
          className="viewPart "
          style={{
            paddingRight: "2%",
            marginTop: "0",
          }}
        >
          {
            data.length > 0 ? 
            <div >
            
                    
            <Table  
                style={{ display: !displayNoDataImage ? "block" : "none" }}
               
                //  dataSource={dataColumn}
                columns={columns} 
                bordered
                scroll={{y:394}}
                dataSource={data} 
                // pagination={{ pageSizeOptions: [5,10,20,50,100]}}
            />
                    {/* </div>
                  </>
                );
              })} */}
            </div>
            
            : null
          }
         
        </div>
      </div>

      {/* Modal for displaying the template  */}
      <Modal
        title={"View Template"}
        width={800}
        keyboard
        bodyStyle={{ overflow: "auto" }}
        closable={false}
        visible={isViewModalVisible}
        // bodyStyle={{ overflow: "scroll" }}
        onOk={() => {
          setIsViewModalVisible(false);
        }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        {notifChannel === "SMS" ? (
         <div
         id="master"
         dangerouslySetInnerHTML={{ __html: template }}
         style={{ whiteSpace: "normal" }}
       />
        ) : (
          <div
            id="master"
            dangerouslySetInnerHTML={{ __html: template }}
            style={{ whiteSpace: "normal" }}
          />
        )}
      </Modal>

      <Modal
      centered
      footer = {null}
      visible = {viewAllModalVisible}
      closable = {false}
      width={300}
      bodyStyle={{paddingTop:'50px',paddingBottom:'50px',margin:'auto'}}
      >
        <div id="allTempModal" >
          <div><Spin indicator={<LoadingOutlined style={{fontSize:'30px',color:'#EA7327'}}/>} /></div>
       
          <div ><h3>Loading Template...</h3></div>
        </div>
      </Modal>
      <Detector
        onChange={(isOnline) => handleInternetChange(isOnline)}
        // render method is necessary
        render={({ online }) => <div></div>}
      />
    </>
  );
}
function customFetch(arg0: { results: number | undefined; page: number | undefined; sortField: any; sortOrder: any; }) {
  throw new Error("Function not implemented.");
}

