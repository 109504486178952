/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { Card, Modal } from "antd";
import {  Detector } from "react-detect-offline";


import "./Styles/home.css";
interface HomePageProps {}

const HomePage: React.FunctionComponent<HomePageProps> = () => {

  const [isOnline, setIsOnline] = React.useState(window.navigator.onLine);

  // to display warning even after the page is refreshed
  if (!isOnline ) {
    warning(
      "No Internet Connection",
      "It seems you have lost your internet connection...Please fix and retry!",
      false,
      false
    );
  }else{
    Modal.destroyAll();
  }

  const SelectFields = useNavigate();
  const { Meta } = Card;
  
  React.useEffect(()=>{
    if(window.localStorage.getItem('isAuthenticated') === 'false'){
      SelectFields('/')
    }
  },[])
  function goToHomePage(){
    SelectFields('/home')
  }

  function warning(title: string, content: string,okBtnVisible:boolean,cancelBtnVisible:boolean) {
    Modal.confirm({
      title: title,
      content: content,
      okButtonProps:{style:{visibility:`${okBtnVisible?'visible':'hidden'}`}},
      cancelButtonProps:{style:{visibility:`${cancelBtnVisible?'visible':'hidden'}`}},
      cancelText:"Go to Home Page",
      closable : false,
      keyboard:false,
      onCancel:goToHomePage
    });
    
  }
  function handleInternetChange(isOnline_) {
    setIsOnline(isOnline_); 
  }

  return (
    <div>
      <Header homebtnVisible = {false}/>
      <h1 style={{textAlign:'center', paddingTop:'20px'}}>Welcome to the Template Designer</h1>
      <div className="homeCardContainer">
            <Card
              hoverable
              className="cardprops"
              onClick={() => SelectFields("/options")}
              style={{ width: 300 ,padding:'20px'}}
              cover={
                <img
                  width={48}
                  alt="example"
                  style={{height:'250px'}}
                  src={"https://template-designer-images-bucket.s3.amazonaws.com/create-template.png"}
                />
              }
            >
              <Meta
                className="card"
                //  avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                title="Create Template"
                description="Create a New Template "
              />
            </Card>
            <Card
              hoverable
              className="cardprops"
              onClick={() => SelectFields("/viewtemplate")}
              style={{ width: 320 ,padding:'20px'}}
              cover={
                <img
                width={48}
                  alt="example"
                  style={{height:'250px'}}
                  src={"https://template-designer-images-bucket.s3.amazonaws.com/view-template.png"}
                />
              }
            >
              <Meta
                className="card"
                //  avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                title="View Template"
                description="Display Available Templates "
              />
            </Card>
            <Card
              hoverable
              className="cardprops"
              onClick={() => SelectFields("/updatetemplate")}
              style={{ width: 300 ,padding:'20px'}}
              cover={
                <img
                  width={50}
                  alt="example"
                  style={{height:'250px'}}
                  src={"https://template-designer-images-bucket.s3.amazonaws.com/update-template.png"}
                />
              }
            >
              <Meta
                className="card"
                //  avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                title="Update Template"
                description="Update an Existing Template "
              />
            </Card>
            <Card
              hoverable
              className="cardprops"
              onClick={() => SelectFields("/deletetemplate")}
              style={{ width: 300 ,padding:'20px'}}
              cover={
                <img
                  width={60}
                  alt="example"
                  style={{height:'250px'}}
                  src={"https://template-designer-images-bucket.s3.amazonaws.com/delete-template.png"}
                />
              }
            >
              <Meta
                className="card"
                //  avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                title="Delete Template"
                description="Delete an Existing Template "
              />
            </Card>
      </div>

      <Footer />
      
      <Detector
        onChange={(isOnline) => handleInternetChange(isOnline)}
        // render method is necessary
        render={({ online }) => (
          <div></div>
        )}
      />
    </div>
  );
};

export default HomePage;
