/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { SyntheticEvent } from "react";
import {
  Input,
  List,
  Button,
  Select,
  Modal,
  Space,
  Skeleton,
  message,
} from "antd";
import CopyButton from "./CopyButton";
import "./Styles/searchField.css";
import { PlusOutlined } from "@ant-design/icons";
import { Option } from "antd/lib/mentions";
import { INITIAL_FIELDS } from "../Utils/Fields";
import { CONSTANTS } from "../constants/constants";

interface SearchieldProps {
  searchParentCallback?: any;
  scrollable?: any;
  height: string;
  loading?: boolean;
}

const SearchField: React.FC<SearchieldProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = React.useState(false);
  const [isAddNewVariableModalOKDisabled, setIsAddNewVariableModalOKDisabled] =
    React.useState<string>("");
  const [newlyAddedVariable, setNewlyAddedVariable] = React.useState({
    fieldName: "",
    fieldDescription: "",
    dataType: "string",
  });
  const [fields, setFields] = React.useState(INITIAL_FIELDS);
  const [fieldName, setFieldName] = React.useState<string>("");
  const [fieldDiscription, setFieldDiscription] = React.useState<string>("");
  const [fieldCode, setFieldCode] = React.useState<string>("");
  const [dataType, setDataType] = React.useState<string>("");

  const { Search } = Input;

  const showModal = () => {
    setIsAddModalVisible(true);
  };

  function warning() {
    Modal.warning({
      title: CONSTANTS.DUPLICATE_VARIABLE_TITLE,
      content: CONSTANTS.DUPLICATE_VARIABLE_MESSAGE,
    });
  }

  function handleParentCallback(value: any) {
    // console.log(value);
    props.searchParentCallback(value);
  }

  function addNewVariable() {
    let newVar = {
      id: fields.length + 1,
      ...newlyAddedVariable,
      fieldCode: `{{${newlyAddedVariable.fieldName}}}`,
    };
    let flag = 0;
    if (newlyAddedVariable.fieldName) {
      // eslint-disable-next-line array-callback-return
      INITIAL_FIELDS.map((fields) => {
        if (
          fields.fieldName.toLowerCase() ===
            newlyAddedVariable.fieldName.toLowerCase() &&
          fields.dataType === newlyAddedVariable.dataType
        ) {
          flag = 1;
        }
      });
      if (flag === 0) {
        message.info(`${newlyAddedVariable.fieldName} Variable Added!`);
        INITIAL_FIELDS.push(newVar);
        setIsAddModalVisible(false);
        setIsAddNewVariableModalOKDisabled("");
        //refresh the input fields
        newlyAddedVariable.dataType = "string";
        newlyAddedVariable.fieldName = "";
        newlyAddedVariable.fieldDescription = "";
      } else {
        warning();
      }
    }
  }

  const handleOkAdd = () => {
    message.config({ maxCount: 0, duration: 1 });
    const regex_emoji =
      /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;
    console.log(regex_emoji.test(newlyAddedVariable.fieldName));
    if (
      regex_emoji.test(newlyAddedVariable.fieldName) ||
      regex_emoji.test(newlyAddedVariable.fieldDescription)
    ) {
      message.warning("Emojis are not allowed!");
    } else {
      addNewVariable();
    }
  };

  const handleNewVariableInputChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    const name = target.name;
    const value = target.value;
    let temp = "";
    value.trim() ? (temp = "a") : (temp = "");
    setIsAddNewVariableModalOKDisabled(temp.trim());

    setNewlyAddedVariable({ ...newlyAddedVariable, [name]: value });
  };

  const handleOk = () => {
    setIsModalVisible(false);
    let newField = {
      id: INITIAL_FIELDS.length + 1,
      fieldName: fieldName,
      fieldDescription: fieldDiscription,
      fieldCode: `{{${fieldCode}}}`,
      dataType: dataType,
    };

    INITIAL_FIELDS.push(newField);

    setFields(INITIAL_FIELDS);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSearch = (value: any) => {
    if (!value) {
      setFields(INITIAL_FIELDS);
    } else {
      setFields(
        INITIAL_FIELDS.filter((field) =>
          field.fieldName.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  function handleSelectChange(value: string) {
    console.log(value);

    setNewlyAddedVariable({
      ...newlyAddedVariable,
      dataType: value,
    });
  }
  function handleEnterPress(e) {
    if (e.key === "Enter") {
      handleOkAdd();
    }
  }

  return (
    <>
      <div>
        <div>
          <Search
            placeholder="Search"
            onSearch={handleSearch}
            onChange={handleSearch}
            enterButton
            allowClear
          />

          <div style={{ height: props.height, overflowY: "scroll" }}>
            <List
              size="small"
              bordered
              // loading = {props.loading}
              dataSource={fields}
              renderItem={(item) => (
                <List.Item
                  key={`${item.id}`}
                  actions={[
                    <Space>
                      <Skeleton.Button
                        active={true}
                        style={{ display: props.loading ? "block" : "none" }}
                      />
                      <CopyButton
                        fieldCode={item.fieldCode}
                        visible={!props.loading ?? false}
                        parentCallback={handleParentCallback}
                      />
                    </Space>,
                    // <DeleteButtom
                    //   fieldCode={item.fieldCode}
                    //   onDelete={(fieldCode: any) => {
                    //     setFields(
                    //       fields.filter((field) => field.fieldCode !== fieldCode)
                    //     );
                    //   }}
                    // />,
                  ]}
                >
                  <Skeleton loading={props.loading} active>
                    <List.Item.Meta
                      // description={item.fieldDescription + `(${item.dataType})`}
                      description={
                        <div>
                          {item.fieldDescription}
                          <br />
                          <i>
                            <b>{`(${item.dataType})`}</b>
                          </i>
                        </div>
                      }
                      title={<b>{item.fieldName}</b>}
                      children={<div>{item.fieldCode}</div>}
                    />
                  </Skeleton>
                  {/* <List.Item.Meta
                    description={`(${item.dataType})`}

                  /> */}
                </List.Item>
              )}
            />
          </div>
        </div>
        <Button
          className="addbutton"
          icon={<PlusOutlined />}
          onClick={showModal}
          disabled={props.loading}
          block
        >
          Add new Variable
        </Button>

        <div className="modContainer">
          <Modal
            title="Add New Variable"
            visible={isAddModalVisible}
            onOk={handleOkAdd}
            okButtonProps={{ disabled: !isAddNewVariableModalOKDisabled }}
            onCancel={() => {
              setIsAddNewVariableModalOKDisabled("");
              newlyAddedVariable.dataType = "string";
              newlyAddedVariable.fieldName = "";
              newlyAddedVariable.fieldDescription = "";
              setIsAddModalVisible(false);
            }}
            width="250px"
          >
            <Space direction="vertical">
              <Input
                placeholder=" Name"
                name="fieldName"
                style={{ border: "1px solid black" }}
                onKeyDown={handleEnterPress}
                value={newlyAddedVariable.fieldName}
                onChange={handleNewVariableInputChange}
              />
              <Input
                placeholder=" Description"
                name="fieldDescription"
                style={{ border: "1px solid black" }}
                onKeyDown={handleEnterPress}
                value={newlyAddedVariable.fieldDescription}
                onChange={handleNewVariableInputChange}
              />

              <Input.Group compact>
                <div className="addbutton">
                  <h5>Select Data type :</h5>
                </div>
                <Select
                  style={{ width: "100%", border: "1px solid black" }}
                  defaultValue="string"
                  value={newlyAddedVariable.dataType}
                  onChange={handleSelectChange}
                >
                  <Option value="string">String </Option>
                  <Option value="number">Number</Option>
                  <Option value="boolean">Boolean</Option>
                  <Option value="date">Date</Option>
                </Select>
              </Input.Group>
            </Space>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default SearchField;
