import * as React from "react";
import { Form, Input, Button, Checkbox, Card, Row, Col } from "antd";
import "./Styles/login.css";
// import logo from "../assets/acertus.jpg";
import { useNavigate } from "react-router-dom";

interface LoginProps {}

const Login: React.FunctionComponent<LoginProps> = () => {
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    console.log("Success:", values);
    let role = '';
    if(values.username === 'a'){
      role = 'admin';
    }else{
      role = 'user'
    }
    window.localStorage.setItem('role',role);
    window.localStorage.setItem('isAuthenticated','true');
    navigate("/home");
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <Row>
        <Col span={8} />

        <Col span={8} style={{ marginTop: 100 }}>
          <Card
            style={{
              width: 350,
            }}
            className="card-center"
          >
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item>
                <img src={"https://template-designer-images-bucket.s3.amazonaws.com/acertus.jpg"} alt="brand logo" className="brand_logo" />
              </Form.Item>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{ offset: 8, span: 16 }}
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" className="button">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={8} />
      </Row>
    </>
  );
};

export default Login;
