import * as React from "react";
import "./Styles/header.css";
// import logo from "../assets/acertus_logo.png";
import { Header } from "antd/lib/layout/layout";
import { Button } from "antd";

import { useNavigate } from "react-router-dom";
import { HomeOutlined, LogoutOutlined } from "@ant-design/icons";
interface HeaderProps {
  pageTitle?: string;
  homebtnVisible: boolean;
  handleParentClick?: () => void;
}

const MyHeader: React.FunctionComponent<HeaderProps> = (props) => {
  const Logout = useNavigate();

  const handleBtnClick = () => {
    // if(props.pageTitle === "Create Template"){
    if (props.handleParentClick !== undefined) {
      props.handleParentClick();
    } else {
      Logout("/home");
    }
  };

  const handleLogout = () => {
    window.localStorage.setItem("isAuthenticated", "false");
    Logout("/");
  };

  let homebtn = (
    <Button
      type="default"
      className="homeBtn"
      size="large"
      style={{ marginRight: "10px" }}
      onClick={handleBtnClick}
    >
      <HomeOutlined /> Home
    </Button>
  );
  return (
    <div>
      <Header className="header-order-div">
        <div className="imgContainer">
          <img src={"https://template-designer-images-bucket.s3.amazonaws.com/acertus_logo.png"} className="brand-logo" alt="Vinlocity logo" />
        </div>
        <div className="pageName">
          <h2>{props.pageTitle}</h2>
        </div>
        <div className="btnContainer">
          {props.homebtnVisible && homebtn}

          <Button className="logout-button" size="large" onClick={handleLogout}>
            <LogoutOutlined /> Logout
          </Button>
        </div>
      </Header>
    </div>
  );
};

export default MyHeader;
