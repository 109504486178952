import axios from "axios";

export default function useTriggerAPI(){

    const fetchData = async (payload:any) => {
      
      let res = await axios.post(`${process.env.REACT_APP_BASE_URL}template_design`,payload);

          let data = await res.data;
          let status= await res.status;
          console.log("responses", res)
          console.log("data",data)
          console.log("res.status",status)
          return data;
         
    }

    return {fetchData}
}    