/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "./Styles/CreateTemplateSource.css";

import {
  Button,
  Col,
  Divider,
  Input,
  Layout,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
  Upload,
} from "antd";
import { SyntheticEvent, useEffect, useState } from "react";
import { Option } from "antd/lib/mentions";
import MyHeader from "../components/Header";
import DOMPurify from "dompurify";
import { Content } from "antd/lib/layout/layout";
import Footer from "../components/Footer";
import { CONSTANTS } from "../constants/constants";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import {  Detector } from "react-detect-offline";

// Turn a HTML string into an AST
// var htmlTagValidator = require("html-tag-validator");
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";
import useTriggerAPI from "../hooks/useTriggerAPI";
import { INITIAL_FIELDS } from "../Utils/Fields";

export default function CreateTemplateFromSourceCode() {
  const nav = useNavigate();

  const location = useLocation();
  const fetchedState: any = location.state;

  useEffect(() => {
    if (window.localStorage.getItem("isAuthenticated") === "false") {
      nav("/");
    }
  }, []);
  const [template, setTemplate] = useState("");
  const [visible, setVisible] = useState(false);
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  

 // const [textAreaInput, setTextAreaInput] = useState("");
  const [isTempNameInputDisabled, setIsTempNameInputDisabled] = useState(false);
  const [subjectNameInput, setSubjectNameInput] = useState(fetchedState?fetchedState.subject:"");
  const [saveTempIsLoading, setSaveTempIsLoading] = useState(false);

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  const [isModalVisibleSubject, setIsModalVisibleSubject] = useState(false);
  const {fetchData} = useTriggerAPI();
  const [isAddModalVisibleSubject, setIsAddModalVisibleSubject] = useState(false);
  const [isAddNewVariableModalOKDisabledSubject, setIsAddNewVariableModalOKDisabledSubject] = useState<string>("");
  const [newlyAddedVariableSubject, setNewlyAddedVariableSubject] = useState({
    fieldName: "",
    fieldDescription: "",
    dataType: "",
  });

  const [clickedFieldNameSubject, setClickedFieldNameSubject] = useState("");
 const [activeStateSubject, setActiveStateSubject] = useState({});
 const [fields, setFields] = useState(INITIAL_FIELDS); 
 const [isAnyVariableClickedSubject, setIsAnyVariableClickedSubject] = useState(false);
 const [design, setDesign] = useState("");
 const [cursorPosition, setCursorPosition] = useState<any>();
 const [stateValue, setStateValue] = useState(fetchedState);
 const [tempName, setTempName] = useState( fetchedState?fetchedState.subject:"Template Name");
 const [textAreaInput, setTextAreaInput] = useState(
  fetchedState ? fetchedState.payload : ""
);
const [payloadCopy, setPayloadCopy] = useState(
  fetchedState ? fetchedState.payload : ""
);

const [version, setVersion] = useState(
  fetchedState ? fetchedState.version : ""
);
const [loading, setLoading] = useState(false);

const [modalTextHead, setModalTextHead] = useState(fetchedState ? "Update" : "Create");
const [btnTextHead, setBtnTextHead] = useState(
  fetchedState ? "Update" : "Save"
);
 
  
  const key = 'updatable';

  function getDataType(fieldCode: string) {

    for (let i = 0; i < INITIAL_FIELDS.length; i++) {
      let fieldItem = INITIAL_FIELDS[i];

      if (fieldItem.fieldCode === fieldCode) {
        return fieldItem.dataType;
      }
    }
  }

  function getCurlyBracketsCount(inputString: string) {
    let count = 0;
    for (var i = 0; i < inputString.length; i++) {
      let braces = inputString[i];
      if (braces === "{" || braces === "}") count++;
    }
    return count;
  }

  function extractVariablesFromInput(inputData: string) {
  
    let input_data: any =[];
    input_data = inputData.match(/{+([^ ]+)}+|{+ ([^ ]+) }+|{+([^ ]+) }+|{+ ([^ ]+)}+/g)


    return input_data ;
    
  }

  function getTemplateVariables() {

    let result: any = [];
    let fieldCodes: string[] = extractVariablesFromInput(textAreaInput);
    let message: string[]=extractVariablesFromInput(subjectNameInput)
    if(fieldCodes !== null) {
      fieldCodes.map((field_data) => {
  
        let obj: any = {};
        obj["name"] = field_data.substring(2, field_data.length - 2).trim();
        obj["type"] = getDataType(field_data) ?? "string"; // if datatype is undefined then we take string as default
        result.push(obj);
      });
    }
    if(message !== null) {
      message.map((field_data) => {
  
        let obj: any = {};
        obj["name"] = field_data.substring(2, field_data.length - 2).trim();
        obj["type"] = getDataType(field_data) ?? "string"; // if datatype is undefined then we take string as default
        result.push(obj);
      });
    }

    return result;
  }

  async function handleSaveBtn() {
    
      if (stateValue) {
        setLoading(true);
        updateTemplate();
      } else {
        setIsSaveModalVisible(true);
        saveTemplateToDB();
      }

    }


  async function updateTemplate() {

    try{
    let updateTemplateObject = {
      data: {
        template_id: fetchedState.tempID,
        notification_channel: "EMAIL",
        template_variables: JSON.stringify(getTemplateVariables()),
        template: textAreaInput,
        subject: subjectNameInput || "No Subject",
        request_type: "update",
        version: +fetchedState.version + 1, 
        raw_html: "",
        sourceCode: "source_code"
      },
    };

    let data = await fetchData(updateTemplateObject);

    if (data.status===200) {
      message.success({content:"Template Updated",key});
      setLoading(false);
      nav("/updatetemplate");
    } 
  }catch (error) {
     
      message.error({content:"Error in updating the Template!",key});
      setLoading(false);
    }
    setLoading(false);
  }

  const addVariable = () => {
    setIsModalVisibleSubject(true);
  };
 
  const addVariableOkSubject =() => {
    let textBeforeCursorPosition =  subjectNameInput.substring(0, cursorPosition)
    let textAfterCursorPosition =  subjectNameInput.substring(cursorPosition,  subjectNameInput.length)
    setSubjectNameInput(textBeforeCursorPosition + window.localStorage.getItem("code") + textAfterCursorPosition)
    setIsModalVisibleSubject(false);
  };

  const handleCancelSubject = () => {
    setIsModalVisibleSubject(false);
  };

  const addNewVariableSubject = () => {
    setIsAddModalVisibleSubject(true);
  };
  const openModalSubject = (clickedFieldIndex: string) => {
    setClickedFieldNameSubject(clickedFieldIndex);
    setActiveStateSubject({
      [clickedFieldIndex]: window.localStorage.getItem("code"),
    });
    setIsModalVisibleSubject(true);
  };

  const handleCancelAddSubject = () => {
    newlyAddedVariableSubject.dataType = "string";
    newlyAddedVariableSubject.fieldName = "";
    newlyAddedVariableSubject.fieldDescription = "";
    setIsAddModalVisibleSubject(false);
  };

  const handleNewVariableInputChangeSubject = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    const name = target.name;
    const value = target.value;
    let temp = "";
    value.trim() ? (temp = "a") : (temp = "");
    setIsAddNewVariableModalOKDisabledSubject(temp.trim())
    setNewlyAddedVariableSubject({ ...newlyAddedVariableSubject, [name]: value });
  };

  const onRadioChangeSubject = (e) => {
    window.localStorage.setItem("code", e.target.value.fieldCode);
    window.localStorage.setItem("type", e.target.value.dataType);
    setIsAnyVariableClickedSubject(true);
    setActiveStateSubject({ [clickedFieldNameSubject]: e.target.value.fieldCode });
  };


  
  const handleOkAddSubject = () => {
    let newVar = {
      id: fields.length + 1,
      ...newlyAddedVariableSubject,
      fieldCode: `{{${newlyAddedVariableSubject.fieldName.toLowerCase()}}}`,
    };
    let flag = 0;
    if (newlyAddedVariableSubject.fieldName) {
      INITIAL_FIELDS.map((fields) => {
        if (
          fields.fieldName.toLowerCase() ===
          newlyAddedVariableSubject.fieldName.toLowerCase()
        ) {
          flag = 1;
        }
      });
      if (flag === 0) {
        INITIAL_FIELDS.push(newVar);

        //refresh the input fields
        setIsAddModalVisibleSubject(false);
        newlyAddedVariableSubject.dataType = "string";
        newlyAddedVariableSubject.fieldName = "";
        newlyAddedVariableSubject.fieldDescription = "";
      } else {
        warning(
          CONSTANTS.DUPLICATE_VARIABLE_TITLE,
          CONSTANTS.DUPLICATE_VARIABLE_MESSAGE,
          "OK",
          true,
          false
        );
      }
    }
  };


  // to display warning even after the page is refreshed
  if (!isOnline ) {
    warning(
      "No Internet Connection",
      "It seems you have lost your internet connection...Please fix and retry!",
      "OK",
      false,
      false
    );
  }else{
    Modal.destroyAll();
  }

  // Object for payload
  let createTemplateObject = {
    data: {
      template_id: "",
      notification_channel: "EMAIL",
      template_variables: {},
      template: textAreaInput ,
      request_type: "create",
      subject: subjectNameInput || "No Subject",
      version: 1,
      raw_html: "",
      sourceCode: "source_code"
    },
  };
  // alerts
  function success() {
    Modal.success({
      content: `${tempName} has been Saved!`,
      onOk: handleSuccessOK,
      // onCancel :handleSuccessCancel,
      okText: "Back to Home",
      keyboard: false,
    });
  }
  function handleSuccessOK() {
    window.location.href = `${window.location.origin}/home`;
  }
  function handleErrorAlert() {
    message.error({content:"There is a HTML error", key});
    setIsSaveModalVisible(false);
    setVisible(false);
  }

  // function validate_variable_braces (input)  {
  //   let regex = /[^{\}]+(?=})/g 
  //    if (input.match(regex)!== null) {
  //     let res =  input.match(regex)[0];
  //     let indexOfWord = input.indexOf(res);
  //     let firstPart = input.substring(0,indexOfWord)
  //     let secondPart = input.substring(firstPart.length+res.length)
  //     if(firstPart.length !== 2 || secondPart.length !==2){
  //         return true; 
  //     }else{
  //         return false;
  //     }
  //   }
  //   else{
  //     return true;
  //   }
  // }
  function validate_variable_braces (input)  {
    let regex = /[^{\}]+(?=})/g
    let invalid_braces = false; 	
	
     if (input.match(regex)!== null) // condition checking for null 
{
      let res =  input.match(regex)[0];//{{vin_id}}--vin_id

      let word_starting_pos = input.indexOf(res);//should be 2
    
      let opening_braces = input.substring(0,word_starting_pos )//{{
      
      let closing_braces = input.substring(opening_braces.length+res.length)//}}
    
      if(opening_braces.length !== 2 || closing_braces.length !==2){
	   invalid_braces  = true; 
          return invalid_braces  ; 
      }else{
	    invalid_braces = false;
          return invalid_braces ;
      }
    }
    else{
      invalid_braces  = true; 
          return invalid_braces 
    }
  }

  const hightlightWithLineNumbers = (input, language) =>
    highlight(input, language)
      .split("\n")
      .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
      .join("\n");
      //We are checking  conditions here--
      //1. In the line number 360, we are checking for valid html code.
      //2. In the line number 366, we are checking for valid variables.
      //3. In the line number 377, we are checking for <input> tag
      async function generateViewHandler() {
        if(/<\/?[a-z][\s\S]*>/i.test(textAreaInput)) {
        let flag=0;
        let result = textAreaInput.match(/{+([^ ]+)}+|{+ ([^ ]+) }+|{+([^ ]+) }+|{+ ([^ ]+)}+/g)
          if(result!== null){
            for(let i=0; i<result.length; i++){
              if(validate_variable_braces(result[i])){
              flag=1;
              break;
              }  
           };
          }
          if(flag===1){
            Modal.error({  title: "There are errors in template, Please Fix it.",content:"Variable braces (closing and opening) count might be mismatching, please check"})
          }
          else if((/[<]\b(input)\b/).test(textAreaInput)){
                Modal.error({ title: "Error in your Template",content:"Templates of upload, submit etc... are not supported."})
          }else{
            setTemplate(textAreaInput);
            setVisible(true);
            setIsTempNameInputDisabled(true);
          }
      } else {
          Modal.error({  title: "There are errors in template, Please Fix it.",content:'Invalid HTML code!'})
        } 
    }
    

  function viewTemplateHandler() {
    let doc = document.getElementById("master") as HTMLDivElement;

    setIsSaveModalVisible(true);
    setIsTempNameInputDisabled(true);
    setTempName("");
  }
  function handleEnterPress(e) {
    if (e.key === "Enter") {
      if(tempName && subjectNameInput){
        handleTemplateSave();
      }
    }
  }

  
  async function saveTemplateToDB(){
    setSaveTempIsLoading(true);

    let mDoc = document.getElementById("master");
    createTemplateObject["data"]["template_id"] = tempName;
   // createTemplateObject["data"]["template_variables"] = "";
     createTemplateObject["data"]["template_variables"] = JSON.stringify(
      getTemplateVariables());
    createTemplateObject["data"]["template"] = textAreaInput;
    createTemplateObject["data"]["raw_html"] = design;


    try {
      const data = await fetchData(createTemplateObject);
      if (data.status === 201) {
        setSaveTempIsLoading(false);
        success();
      } 

    } catch (error:any) {
      setSaveTempIsLoading(false);
      if (error.response.status === 409) {
        message.error({content:CONSTANTS.SAME_TEMPLATE_NAME, key});
        setIsTempNameInputDisabled(false);
        setSaveTempIsLoading(false);
      } else{

      message.error({content:CONSTANTS.ERROR_IN_FETCHING, key});
      setSaveTempIsLoading(false);
      }
    }

  }



  function handleTemplateSave() {

    message.config({ maxCount: 0, duration: 1 });
    const regex_emoji = /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;
      if(regex_emoji.test(tempName) || regex_emoji.test(subjectNameInput)){
        message.warning({content:'Emojis are not allowed!', key});
      }else{
        handleSaveBtn();
      }

   
  }
  function goToHomePage(){
    nav('/home')
  }
  function warning(title: string, content: string,okText:string,okBtnVisible:boolean,cancelBtnVisible:boolean) {
    Modal.confirm({
      title: title,
      content: content,
      okButtonProps:{style:{visibility:`${okBtnVisible?'visible':'hidden'}`}},
      cancelButtonProps:{style:{visibility:`${cancelBtnVisible?'visible':'hidden'}`}},
      okText : okText,
      cancelText:"Exit to homepage",
      closable : false,
      keyboard:false,
      onCancel:goToHomePage
    });
  }
  function handleHomeButtonClick(){
    if(textAreaInput){
      warning("Save Template", "Do you want to Exit to homepage or Continue ?","Continue",true,true);
    }else{
      goToHomePage()
    }
  }
  function handleInternetChange(isOnline_) {
    setIsOnline(isOnline_); 
  }
  
  return (
    <Layout>
      <MyHeader 
      homebtnVisible={true}
      pageTitle={stateValue !== null ? "Update Template" : ""}
       handleParentClick={handleHomeButtonClick}/>
      <Content id="content">

        <Row id="row">
          <Col id="col1" span={6}>
            <div className="sider">
              <div className="code">
                <h2 id="sourceHead">Enter your HTML Code</h2>
                <ArrowRightOutlined id="iconArrow" />
              </div>
              <Divider className="divider">or</Divider>
              <div className="uploadContainer">
                <Upload
                  accept=".html"
                  showUploadList={false}
                  beforeUpload={(file) => {
                    
                    if(file.name.split('.')[1] === 'html'){

                      const reader = new FileReader();
                      
                      reader.onload = (e) => {
                        
                        setTextAreaInput(e?.target?.result?.toString() ?? "");
                      };
                      reader.readAsText(file);
                      return false;
                    }else{
                      message.error({content:'Only HTML files are allowed!', key})
                    }
                  }}
                >
                  <Button> Upload HTML Code</Button>
                </Upload>
              </div>
            </div>
          </Col>
          <Col span={18} style={{ padding: "10px" }}>
            <div id="textAreaSource">
              <Editor
                value={textAreaInput}
                onValueChange={(code) => setTextAreaInput(code)}
                highlight={(code) =>
                  hightlightWithLineNumbers(code, languages.html)
                }
                padding={10}
                textareaId="codeArea"
                // id="textAreaSource"
                className="editor"
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 14,
                  outline: 0,
                }}
              />
            </div>
            <Button
              type="primary"
              block
              disabled={!textAreaInput}
              onClick={generateViewHandler}
            >
              Generate View
            </Button>
          </Col>
        </Row>
      </Content>

      <Modal
        title="Template View"
        centered
        visible={visible}
        okText={`${btnTextHead} Template`}
        onOk={viewTemplateHandler}
        onCancel={() => setVisible(false)}
        width={800}
      >
        <div className="templateView">
          <div
            id="master"
            dangerouslySetInnerHTML={{ __html:(template) }}
          />
        </div>
      </Modal>
      <Footer />
      <Modal
        title={`${modalTextHead} Template as`}
        visible={isSaveModalVisible}
        // okText="Save"
        // okButtonProps={{ disabled: !tempName.trim() || !subjectNameInput.trim(), loading: saveTempIsLoading }}
        // onOk={handleTemplateSave}
         onCancel={() => setIsSaveModalVisible(false)}
        footer={[
          <Tooltip title="Add a Variable">
          <Button onClick={addVariable}
          disabled= { !subjectNameInput.trim()}
          >
           Add variable
          </Button>
          </Tooltip>,
          <Button
            key="back"
            onClick={() => {
              setIsSaveModalVisible(false)
            }}
            disabled={saveTempIsLoading}
          >
            Cancel
          </Button>,
          <Button type="primary" key="edit" onClick={handleTemplateSave} 
          disabled= { !subjectNameInput.trim()}
          loading= {saveTempIsLoading}
          >
            {btnTextHead}
          </Button>, 
        ]}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          {
            fetchedState ?  
              null :
        
          <Input
            placeholder="Enter template name"
            // value={fetchedState.tempID ? fetchedState.tempID: tempName}
            value={tempName}
            id="input"
            onKeyDown={handleEnterPress}
            // disabled={fetchedState.tempID}
            onChange={(e) => {
              setTempName(e.target.value);
            }}
          ></Input>
}
          <Input
            placeholder="Email Subject (Required)"
            name="tempName"
            id="input"
            onKeyDown={handleEnterPress}
            value={subjectNameInput}
            width="100%"
            onChange={(e) => {
              setSubjectNameInput(e.target.value);
              setCursorPosition(e.target.selectionStart)
            }}
          />
        </Space>
      </Modal>

       {/* Add new Variable Modal */}
       <div className="modContainer">
        <Modal
          title="Add New Variable"
          visible={isAddModalVisibleSubject}
          onOk={handleOkAddSubject}
          okButtonProps = {{disabled: !isAddNewVariableModalOKDisabledSubject}}
          onCancel={handleCancelAddSubject}
          width="250px"
          style={{ borderRadius: "10px" }}
        >
          <Space direction="vertical">
            <Input
              id="input"
              placeholder="Field Name"
              name="fieldName"
              value={newlyAddedVariableSubject.fieldName}
              onChange={handleNewVariableInputChangeSubject}
            />
            <Input
              id="input"
              placeholder="Field Description"
              name="fieldDescription"
              value={newlyAddedVariableSubject.fieldDescription}
              onChange={handleNewVariableInputChangeSubject}
            />

            <Input.Group compact>
              <div className="addbutton">
                <h5>Select Data type :</h5>
              </div>
              <Select
                style={{ width: "100%", borderRadius: "10px" }}
                defaultValue="string"
                value={newlyAddedVariableSubject.dataType}
                onChange={(value) =>
                  setNewlyAddedVariableSubject({
                    ...newlyAddedVariableSubject,
                    dataType: value,
                  })
                }
              >
                <Option value="string">String </Option>
                <Option value="number">Number</Option>
                <Option value="boolean">Boolean</Option>
                <Option value="date">Date</Option>
              </Select>
            </Input.Group>
          </Space>
        </Modal>
      </div>
      <Modal
        title="Select a Variable to add"
        visible={isModalVisibleSubject}
        onOk={addVariableOkSubject}
        closable={false}
        style={{ top: 10 }}
        mask
        footer={[
          <Button key="back" onClick={handleCancelSubject}>
            Close
          </Button>,
          <Button
            key="addField"
            type="primary"
            onClick={addVariableOkSubject}
            disabled={!isAnyVariableClickedSubject}
          >
            Select Variable
          </Button>,
          <Button type="primary" onClick={addNewVariableSubject}>
            Add New Variable
          </Button>,
        ]}
      >
        <Radio.Group onChange={onRadioChangeSubject}>
          {fields.map((field) => {
            return (
              <div style={{ display: "block" }}>
                <Radio value={field} name={field.fieldName}>
                  <div>
                    <h4>{field.fieldName}</h4>
                  </div>
                </Radio>
              </div>
            );
          })}
        </Radio.Group>
      </Modal>
      
      <Detector
        onChange={(isOnline) => handleInternetChange(isOnline)}
        // render method is necessary
        render={({ online }) => (
          <div></div>
        )}
      />
    </Layout>
  );
}
