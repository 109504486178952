/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Input, message, Modal, Radio, Select, Space, Tooltip } from "antd";
import { RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import SearchField from "../components/SearchField";
import MyHeader from "../components/Header";
import DOMPurify from "dompurify";
import { useLocation, useNavigate } from "react-router-dom";
import useTriggerAPI from "../hooks/useTriggerAPI";
import { CONSTANTS } from "../constants/constants";
import { INITIAL_FIELDS } from "../Utils/Fields";
import Footer from "../components/Footer";
import { Detector } from "react-detect-offline";
import { Option } from "antd/lib/mentions";

interface TempInterface {
  tempCallback?: any;
}
export default function CreateFromWizard(props: TempInterface) {

  const nav = useNavigate();
  const location = useLocation();
  const fetchedState: any = location.state;

  const btnfocus: RefObject<HTMLButtonElement> = useRef(null);

  const key = 'updatable';

  const successMessage = () => {
    setTimeout(() => {
      message.success({ content: 'Template Created!', key, duration: 1.5 });
    }, 1000);
      setIsSaveModalVisible(false);
      setVisible(false);
      window.location.href = `${window.location.origin}/home`;
      setSaveTempIsLoading(false);
  };

  useEffect(() => {
    btnfocus.current?.focus();
  }, []);

  useEffect(() => {
    if (window.localStorage.getItem("isAuthenticated") === "false") {
      nav("/");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const emailEditorRef = useRef<any>(null);
  const [searchCopyItems, setSearchCopyItems] = useState<any>();
  const [emailTempalateValue, setEmailTemplateValue] = useState<string>("");
  const [visible, setVisible] = useState(false);
  const [fields, setFields] = useState(INITIAL_FIELDS);

  const [listLoading, setListLoading] = useState(true);
  const [template, setTemplate] = useState("");
  const [payloadCopy, setPayloadCopy] = useState("");
  const [design, setDesign] = useState("");
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const [tempName, setTempName] = useState("");
  const [subjectNameInput, setSubjectNameInput] = useState("");
  const [saveTempIsLoading, setSaveTempIsLoading] = useState(false);
  const [emailSubject, setEmailSubject] = useState(fetchedState?fetchedState.subject:"");
  const [isEditSubjectModalVisible, setIsEditSubjectModalVisible] =
    useState(false);
  const [btnTextHead, setBtnTextHead] = useState(
    fetchedState ? "Update" : "Save"
  );
  const [stateValue, setStateValue] = useState(fetchedState);

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const [isAddNewVariableModalOKDisabled, setIsAddNewVariableModalOKDisabled] = useState<string>("");
  const [newlyAddedVariable, setNewlyAddedVariable] = useState({
    fieldName: "",
    fieldDescription: "",
    dataType: "",
  });

  const [clickedFieldName, setClickedFieldName] = useState("");
 const [activeState, setActiveState] = useState({});
 const [isAnyVariableClicked, setIsAnyVariableClicked] = useState(false);
 
 const [cursorPosition, setCursorPosition] = useState<any>();

  const addVariable = () => {
    setIsModalVisible(true);
  };
  
  const addVariableOk =() => {
    let textBeforeCursorPosition = emailSubject.substring(0, cursorPosition)
    let textAfterCursorPosition = emailSubject.substring(cursorPosition, emailSubject.length)
    let textBeforeCursorPositionSub = subjectNameInput.substring(0, cursorPosition)
    let textAfterCursorPositionSub = subjectNameInput.substring(cursorPosition, emailSubject.length)
    setSubjectNameInput(textBeforeCursorPositionSub + window.localStorage.getItem("code") + textAfterCursorPositionSub)
    setEmailSubject(textBeforeCursorPosition + window.localStorage.getItem("code") + textAfterCursorPosition)
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const addNewVariable = () => {
    setIsAddModalVisible(true);
  };
  const openModal = (clickedFieldIndex: string) => {
    setClickedFieldName(clickedFieldIndex);
    setActiveState({
      [clickedFieldIndex]: window.localStorage.getItem("code"),
    });
    setIsModalVisible(true);
  };

  const handleCancelAdd = () => {
    newlyAddedVariable.dataType = "string";
    newlyAddedVariable.fieldName = "";
    newlyAddedVariable.fieldDescription = "";
    setIsAddModalVisible(false);
  };

  const handleNewVariableInputChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    const name = target.name;
    const value = target.value;
    let temp = "";
    value.trim() ? (temp = "a") : (temp = "");
    setIsAddNewVariableModalOKDisabled(temp.trim())
    setNewlyAddedVariable({ ...newlyAddedVariable, [name]: value });
  };

  const onRadioChange = (e) => {
    window.localStorage.setItem("code", e.target.value.fieldCode);
    window.localStorage.setItem("type", e.target.value.dataType);
    setIsAnyVariableClicked(true);
    setActiveState({ [clickedFieldName]: e.target.value.fieldCode });
  };


  const handleOkAdd = () => {
    let newVar = {
      id: fields.length + 1,
      ...newlyAddedVariable,
      fieldCode: `{{${newlyAddedVariable.fieldName.toLowerCase()}}}`,
    };
    let flag = 0;
    if (newlyAddedVariable.fieldName) {
      INITIAL_FIELDS.map((fields) => {
        if (
          fields.fieldName.toLowerCase() ===
          newlyAddedVariable.fieldName.toLowerCase()
        ) {
          flag = 1;
        }
      });
      if (flag === 0) {
        INITIAL_FIELDS.push(newVar);

        //refresh the input fields
        setIsAddModalVisible(false);
        newlyAddedVariable.dataType = "string";
        newlyAddedVariable.fieldName = "";
        newlyAddedVariable.fieldDescription = "";
      } else {
        warning(
          CONSTANTS.DUPLICATE_VARIABLE_TITLE,
          CONSTANTS.DUPLICATE_VARIABLE_MESSAGE,
          "OK",
          true,
          false
        );
      }
    }
  };


  // to display warning even after the page is refreshed
  if (!isOnline) {
    warning(
      "No Internet Connection",
      "It seems you have lost your internet connection...Please fix and retry!",
      "OK",
      false,
      false
    );
  } else {
    Modal.destroyAll();
  }

  const { fetchData } = useTriggerAPI();

  let createTemplateObject = {
    data: {
      template_id: tempName,
      notification_channel: "EMAIL",
      template_variables: {},
      template: "",
      request_type: "create",
      subject: subjectNameInput || "No Subject",
      version: 1,
      raw_html: "",
      sourceCode:"wizard"
    },
  };

  function handleInternetChange(isOnline_) {
    setIsOnline(isOnline_);
  }

  function handleSearchParentCallback(childData: string[]) {
    // setIsBtnDisabled(true);
    setSearchCopyItems(childData);
  }

  function htmlHasContent(html: string) {
    let flag = 0;
    // href for anchor tag
    let possibleElements = [
      "href",
      "h1",
      "h2",
      "h3",
      "h4",
      "img",
      "<p",
      "span",
      "strong",
    ];
    for (let i = 0; i < possibleElements.length; i++) {
      const elem = possibleElements[i];
      if (html.includes(elem)) {
        flag = 1;
        break;
      }
    }
    // possibleElements.map(elem => {

    // })
    return flag === 1;
  }


  let tempArr: any = [];

  const handlePreview = async () => {
    message.config({ maxCount: 0 });
    emailEditorRef.current.editor.exportHtml(async (data) => {
      const { design, html } = await data;

      let changes = htmlHasContent(html);

      if (html === payloadCopy) changes = false;

      if (changes) {
        // props.tempCallback(html);
        setEmailTemplateValue(html);
        setVisible(true);
        setTemplate(html);
        setDesign(JSON.stringify(design));
        // nav('/createtemplate',{state:{'html':html}})
        // console.log("exportHtml", html);
      } else if (stateValue.html && !changes) {
        message.info({content:"Make some changes to see preview", key});
      } else {
        message.info({content:"Drag & Drop elements to save template", key}, 2);
      }
    });
  };
  function getDataType(fieldCode: string) {
    for (let i = 0; i < fields.length; i++) {
      let fieldItem = fields[i];
      if (fieldItem.fieldCode === fieldCode) {
        return fieldItem;
      }
    }
  }


  function handleUpdateSubjectOk() {
    message.config({ maxCount: 0, duration: 1 });
    const regex_emoji =
      /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;
    if ( regex_emoji.test(emailSubject)) {
      message.warning({content:"Emojis are not allowed!", key});
    } else {
      setSaveTempIsLoading(true);
      handleTemplateUpdate();
    }
  }

  function handleOk() {

    if (stateValue) {
      // handleUpdateSubjectOk();
      setIsEditSubjectModalVisible(true);
    } else {
      setIsSaveModalVisible(true);
      setTempName("");
    }
  }

  async function handleTemplateUpdate() {
    if (searchCopyItems) {
      searchCopyItems.forEach((item: string) => {

        let temp: any = {};
        temp["name"] = item.substring(2, item.length - 2);
        temp["type"] = "string"; // here string is given for all. need to find a way to give int, boolean as needed
        tempArr.push(temp);
      });
    }
    let updateTemplateObject = {
      data: {
        template_id: stateValue.tempID,
        notification_channel: "EMAIL",
        template_variables: JSON.stringify(
          removeDuplicates(tempArr.concat(stateValue.tempVariables))
        ),
        template: template,
        raw_html: design,
        subject: emailSubject,
        request_type: "update",
        version: +stateValue.version + 1,
      },
    };

    try {
      let data = await fetchData(updateTemplateObject);

      if (data.status===200) {
        message.success({content:CONSTANTS.TEMPLATE_UPDATED, key});
        setSaveTempIsLoading(false);
        nav("/updatetemplate");
      }
    } catch (error) {
      setSaveTempIsLoading(false);
      if (error) {
        message.error({content:CONSTANTS.ERROR_IN_UPDATING, key});
        setSaveTempIsLoading(false);
      }
    }
  }

  async function saveWizardTemplateToDB() {
    setSaveTempIsLoading(true);

    // message.loading({ content: "Saving...", key });
    if (searchCopyItems) {
      searchCopyItems.forEach((item: string) => {

        let temp: any = {};
        temp["name"] = item.substring(2, item.length - 2);
        temp["type"] = getDataType(item)?.dataType;
        tempArr.push(temp);
      });
    }

    createTemplateObject["data"]["template_variables"] =
      JSON.stringify(removeDuplicates(tempArr));
    createTemplateObject["data"]["template"] = emailTempalateValue;
    createTemplateObject["data"]["raw_html"] = design;

    try {
    let data = await fetchData(createTemplateObject);
    if (data.status=== 201) {
      successMessage();

    } 
  } catch (error: any) {
    setSaveTempIsLoading(false);
   if (error.response.status === 409) {
      message.error({content:CONSTANTS.SAME_TEMPLATE_NAME, key});
      setSaveTempIsLoading(false);
    } else {
      message.error({content:"Some error occured!", key});
      setSaveTempIsLoading(false);
    }
    setSaveTempIsLoading(false);
  }
  }

  function handleTemplateSave() {
    message.config({ maxCount: 0, duration: 1 });
    const regex_emoji =
      /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;
    if (regex_emoji.test(tempName) || regex_emoji.test(subjectNameInput)) {
      message.warning({content:"Emojis are not allowed!", key});
    } else {
      saveWizardTemplateToDB();
    }
  }

  function handleSubjectEnterPress(e) {
    if (e.key === "Enter") {
      if (subjectNameInput.trim()) {
        handleUpdateSubjectOk();
      }
    }
  }

  function handleEnterPress(e) {
    if (e.key === "Enter") {
      if (tempName.trim() && subjectNameInput.trim()) {
        handleTemplateSave();
      }
    }
  }
  const onReady = () => {
    setListLoading(false);
    onLoad();
  };

  function removeDuplicates(arr: any) {
    return arr.filter(
      (value, index, self) =>
        index ===
        self.findIndex((t) => t.place === value.place && t.name === value.name)
    );
  }
  const onLoad = () => {
    // you can load your template here;
    // console.log(fetchedState.payload);

    const templateJson = JSON.parse(stateValue.payload);
    setPayloadCopy(stateValue.html);
    emailEditorRef.current.editor.loadDesign(templateJson);
  };
  function goToHomePage() {
    window.location.href = `${window.location.origin}/home`;
  }
  function warning(
    title: string,
    content: string,
    okText: string,
    okBtnVisible: boolean,
    cancelBtnVisible: boolean
  ) {
    Modal.confirm({
      title: title,
      content: content,
      okButtonProps: {
        style: { visibility: `${okBtnVisible ? "visible" : "hidden"}` },
      },
      cancelButtonProps: {
        style: { visibility: `${cancelBtnVisible ? "visible" : "hidden"}` },
      },
      okText: okText,
      cancelText: "Exit to homepage",
      closable: false,
      keyboard: false,
      onCancel: goToHomePage,
    });
  }

  const handleHomeButtonClick = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      if (htmlHasContent(html) && html !== payloadCopy) {
        warning(
          "Save Template",
          "Do you want to Exit to homepage or Continue ?",
          "Continue",
          true,
          true
        );
      } else {
        goToHomePage();
      }
    });
  };

  return (
    <>
      <MyHeader
        homebtnVisible
        pageTitle={stateValue !== null ? "Update Template" : ""}
        handleParentClick={handleHomeButtonClick}
      />
      <div
        className="container"
        style={{ display: "flex", padding: "0.7%", gap: "10px" }}
      >
        <div className="partOne" style={{ flex: 2, textAlign: "center" }}>
          {/* <EmailEditor ref={emailEditorRef} onLoad={onLoad} /> */}

          <EmailEditor ref={emailEditorRef} onReady={onReady} />

          <Button
            onClick={handlePreview}
            ref={btnfocus}
            // onKeyDown={()=>alert('hi')}
            type="primary"
            // disabled={!isBtnDisabled}
            style={{ marginTop: "20px", borderRadius: "10px", width: "250px" }}
          >
            Preview Template
          </Button>
        </div>
        <div className="partTwo" style={{ flex: 1 }}>
          <SearchField
            loading={listLoading}
            searchParentCallback={handleSearchParentCallback}
            height="410px"
          />
        </div>
      </div>

      <Footer />

      <Modal
        title="Template View"
        centered
        visible={visible}
        okText={`${btnTextHead} Template`}
        okButtonProps={{ loading: saveTempIsLoading }}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
        width={800}
      >
        <div className="templateView">
          <div
            id="master"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(template) }}
          />
        </div>
      </Modal>

      <Modal
        title="Save Template as"
        visible={isSaveModalVisible}
        // okText="Save"
        // okButtonProps={{
        //   disabled: !tempName.trim() || !subjectNameInput.trim(),
        //   loading: saveTempIsLoading,
        // }}
        // onOk={handleTemplateSave}
        onCancel={() => setIsSaveModalVisible(false)}
        footer={[
          <Tooltip title="Add a Variable">
          <Button onClick={addVariable}
          disabled= {!subjectNameInput.trim()}
          >
           Add variable
          </Button>
          </Tooltip>,
          <Button
            key="back"
            onClick={() => {
              setIsSaveModalVisible(false)
            }}
            disabled={saveTempIsLoading}
          >
            Cancel
          </Button>,
          <Button type="primary" key="edit" onClick={handleTemplateSave} 
          disabled= {!tempName.trim() || !subjectNameInput.trim()}
          loading= {saveTempIsLoading}
          >
            Save
          </Button>, 
        ]}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Input
            placeholder="Enter template name"
            value={tempName}
            id="input"
            onKeyDown={handleEnterPress}
            onChange={(e) => {
              setTempName(e.target.value);
            }}
          ></Input>
          <Input
            placeholder="Email Subject (Required)"
            name="tempName"
            id="input"
            onKeyDown={handleEnterPress}
            value={subjectNameInput}
            width="100%"
            onChange={(e) => {
              setSubjectNameInput(e.target.value);
              setCursorPosition(e.target.selectionStart)
            }}
          />
        </Space>
      </Modal>

      <Modal
        title="Update Email Subject"
        visible={isEditSubjectModalVisible}
        // onOk={handleUpdateSubjectOk}
        // closable={false}
        // okButtonProps={{ disabled: !emailSubject.trim(), loading: saveTempIsLoading }}
        // // cancelButtonProps={{disabled:saveTempIsLoading}}
         onCancel={() => {
          setIsEditSubjectModalVisible(false);
        }}
        width="400px"
        footer={[
          <Tooltip title="Add a Variable">
          <Button onClick={addVariable}
          disabled= {!emailSubject.trim()}
          >
           Add variable
          </Button>
          </Tooltip>,
          <Button
            key="back"
            onClick={() => {
              setIsEditSubjectModalVisible(false)
            }}
            disabled={saveTempIsLoading}
          >
            Cancel
          </Button>,
          <Button type="primary" key="edit" onClick={handleUpdateSubjectOk} 
          disabled= {!emailSubject.trim()}
          loading= {saveTempIsLoading}
          >
            Ok
          </Button>, 
        ]}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Input
            placeholder={emailSubject}
            name="tempName"
            id="input"
            onKeyDown={handleSubjectEnterPress}
            value={emailSubject}
            width="100%"
            onChange={(e) => {
              setEmailSubject(e.target.value);
            }}
          />
        </Space>
      </Modal>

      
       {/* Add new Variable Modal */}
       <div className="modContainer">
        <Modal
          title="Add New Variable"
          visible={isAddModalVisible}
          onOk={handleOkAdd}
          okButtonProps = {{disabled: !isAddNewVariableModalOKDisabled}}
          onCancel={handleCancelAdd}
          width="250px"
          style={{ borderRadius: "10px" }}
        >
          <Space direction="vertical">
            <Input
              id="input"
              placeholder="Field Name"
              name="fieldName"
              value={newlyAddedVariable.fieldName}
              onChange={handleNewVariableInputChange}
            />
            <Input
              id="input"
              placeholder="Field Description"
              name="fieldDescription"
              value={newlyAddedVariable.fieldDescription}
              onChange={handleNewVariableInputChange}
            />

            <Input.Group compact>
              <div className="addbutton">
                <h5>Select Data type :</h5>
              </div>
              <Select
                style={{ width: "100%", borderRadius: "10px" }}
                defaultValue="string"
                value={newlyAddedVariable.dataType}
                onChange={(value) =>
                  setNewlyAddedVariable({
                    ...newlyAddedVariable,
                    dataType: value,
                  })
                }
              >
                <Option value="string">String </Option>
                <Option value="number">Number</Option>
                <Option value="boolean">Boolean</Option>
                <Option value="date">Date</Option>
              </Select>
            </Input.Group>
          </Space>
        </Modal>
      </div>
      <Modal
        title="Select a Variable to add"
        visible={isModalVisible}
        onOk={addVariableOk}
        closable={false}
        style={{ top: 10 }}
        mask
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
          <Button
            key="addField"
            type="primary"
            onClick={addVariableOk}
            disabled={!isAnyVariableClicked}
          >
            Select Variable
          </Button>,
          <Button type="primary" onClick={addNewVariable}>
            Add New Variable
          </Button>,
        ]}
      >
        <Radio.Group onChange={onRadioChange}>
          {fields.map((field) => {
            return (
              <div style={{ display: "block" }}>
                <Radio value={field} name={field.fieldName}>
                  <div>
                    <h4>{field.fieldName}</h4>
                  </div>
                </Radio>
              </div>
            );
          })}
        </Radio.Group>
      </Modal>

      <Detector
        onChange={(isOnline) => handleInternetChange(isOnline)}
        // render method is necessary
        render={({ online }) => <div></div>}
      />
    </>
  );
}
