/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import "./Styles/DeleteTemplate.css";

import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Spin,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../constants/constants";
import useTriggerAPI from "../hooks/useTriggerAPI";
import sortFetchedList from "../Utils/sortFetchedList";
// import no_data_img from "../assets/no_data_1.png";
import MyHeader from "../components/Header";
import {  Detector } from "react-detect-offline";
import capitalizeFirstLetter from "../Utils/CapitalizeFirstLetter";
import useGetAllTemplate from "../hooks/useGetAllTemplate";
import "./Styles/ViewTemplate.css";


export default function DeleteTemplate() {
  const nav = useNavigate();
  const [tempID, setTempID] = useState("");

  const [notifChannel, setNotifChannel] = useState("EMAIL");
  const [currentRole, setCurrentRole] = useState<any>("");
  const [fetchedTemplatesList, setFetchedTemplatesList] = useState<any[]>([]);
  const [displayNoDataImage, setDisplayNoDataImage] = useState(true);
  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  // to display warning even after the page is refreshed
  if (!isOnline ) {
    warning(
      "No Internet Connection",
      "It seems you have lost your internet connection...Please fix and retry!",
      false,
      false
    );
  }else{
    if(!isConfirmDeleteModalVisible)
      Modal.destroyAll();
  }


  const { confirm } = Modal;
  useEffect(() => {
    getAllTemplates();
  }, []);

  useEffect(() => {
    if (window.localStorage.getItem("isAuthenticated") === "false") {
      nav("/");
    }
    setCurrentRole(window.localStorage.getItem("role"));
  }, []);

  // The payload to be passed
  let payload = {
    data: {
      template_id: tempID,
      notification_channel: notifChannel,
      request_type: "list_by_templateid",
    },
  };

  const { fetchData } = useTriggerAPI();
  const { fetchAllTemplate } = useGetAllTemplate();

  const [fetchedAllTemplatesList, setFetchedAllTemplatesList] = useState<any[]>(
    []
  );
  const [isSearchClicked, setIsSearchClicked] = useState(false);


  const columns = [
    {
      title: 'Template Name',
      dataIndex: 'template_id',
      key: 'template_id',
      align: 'left' as 'left',
      className: 'ant-table-thead ant-table-cell column',
      
    },
    // {
    //   title:'Subject',
    //   dataIndex: 'subject',
    //   key: 'subject',
    //   width: 150,
    //   align: 'center' as 'center',
    //   className: 'column',
    // },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
      width: 150,
      align: 'center' as 'center',
      className: 'column',
    },
    {
      title: 'Latest Created/Updated',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center' as 'center',
      className: 'column',
      render: (obj) => {
        return(getDateAndTime(obj))
      }
    },
    {
      title: 'Delete Template',
      key: 'operation',
      width: 150,
      align: 'center' as 'center',
      className: 'column',
      render: (obj) =>{

        if (currentRole === "user") {
          if (obj.version !== 1) {
            return(
              <Tooltip title="Delete Template">
              <DeleteOutlined className="modalEditNew"  onClick={() => {
                                handleDeleteTemplate(obj);
                              }} style={{ fontSize: "large" }} />
              </Tooltip>
            )
          }
        } else {
          return(
            <Tooltip title="Delete Template">
            <DeleteOutlined className="modalEditNew"  onClick={() => {
                              handleDeleteTemplate(obj);
                            }} style={{ fontSize: "large" }} />
            </Tooltip>
          )
        }
      } 
    },
  ];

  const getDateAndTime = (timestamp: string) => {
    let date = new Date(timestamp);
    return date.toLocaleString('en-US');
  };


  const data = fetchedTemplatesList.map((row, id)=> ({
    template: row.template,
    template_id: row.template_id,
    subject: row.subject,
    version: row.version,
    created_at: getDateAndTime(row.created_at),
    key: id,
    children: row.children,
    notification_channel: row.notification_channel,
  }))

  async function getAllTemplates(){
    try {
    let data = await fetchAllTemplate(notifChannel);
    data = data.sort(sortByTimeStamp);

     setDisplayNoDataImage(false);
    // // setPaginationVisible(true);
    // // setFetchedAllTemplatesList(data);
    // setFetchedTemplatesList(data);
    // setLoading(false);
    if (!data.status) {
      // This is the condition when the admin is not logged in and there is only master 
      // version available which is not supposed to be deleted by non-admin 
      if(window.localStorage.getItem('role') === 'user' && data.length === 1){
        message.info('No version of this template to delete')
      }
      else if (data.length) {
        setDisplayNoDataImage(false);
        setFetchedTemplatesList((data));
      } else {
        message.error(CONSTANTS.TEMPLATE_NOT_FOUND);
        setDisplayNoDataImage(false);
      }
      setLoading(false);
    }
  } catch (error) {
    message.error(CONSTANTS.ERROR_IN_FETCHING);
    
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }
  }

  function sortByTimeStamp(x, y) {
    let date1 = new Date(x.created_at);
    let date2 = new Date(y.created_at);
    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
  }

  function showConfirm(template) {
    confirm({
      title: "Are you sure you want to delete this Template?",
      icon: <ExclamationCircleOutlined />,
      content: "Template once deleted cannot be retrieved back",
      okType: "link",
      cancelButtonProps: { type: "primary" },
      okButtonProps: { loading: loading, className: "confirmOkBtn", autoFocus:false },
      onOk() {
        handleConfirmationOK(template);
      },
      onCancel() {},
    });
  }

  function handleEnterPress(e) {
    if (e.key === "Enter") {
      handleSearch();
    }
  }

  const handleConfirmationOK = async (template) => {

    setLoading(true);
if(!tempID){
  // setTempID(template.template_id);
}
    // The delete payload for deleting the concerned template
    let deletepayload = {
      data: {
        template_id: tempID ? tempID : template.template_id,
        notification_channel: template.notification_channel,
        version: template.version,
        request_type: "delete",
      },
    };
    try {
      let data = await fetchData(deletepayload);
    if (!data.status ) {
        setLoading(false);
        message.success("Template Deleted");
        payload.data.template_id = payload.data.template_id ? payload.data.template_id : template.template_id;
        handleSearch();
      } else {
        message.error("Error Deleting Template");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async () => {
    message.config({ maxCount: 0, duration: 1 });

    setLoading(true);
    setFetchedTemplatesList([]);
    if (tempID) {
      try {
        payload["data"]["template_id"] = tempID;
        payload["data"]["notification_channel"] = notifChannel;

        let data = await fetchData(payload);
        if (!data.status) {
          // This is the condition when the admin is not logged in and there is only master 
          // version available which is not supposed to be deleted by non-admin 
          if(window.localStorage.getItem('role') === 'user' && data.length === 1){
            message.info('No version of this template to delete')
          }
          else if (data.length) {
            setDisplayNoDataImage(false);
            setFetchedTemplatesList(sortFetchedList(data));
          } else {
            message.error(CONSTANTS.TEMPLATE_NOT_FOUND);
            setDisplayNoDataImage(false);
          }
          setLoading(false);
        }
      } catch (error) {
        message.error("Error occured");
        setLoading(false);
      }
    } else {
      setIsSearchClicked(true);
      getAllTemplates();
      // message.warning(CONSTANTS.TEMPLATE_NAME);
      // setLoading(false);
    }
  };

  const handleDeleteTemplate = async (template) => {
    setIsConfirmDeleteModalVisible(true);
    showConfirm(template);
  };

  function goToHomePage(){
    nav('/home')
  }

  function warning(title: string, content: string,okBtnVisible:boolean,cancelBtnVisible:boolean) {
    Modal.confirm({
      title: title,
      content: content,
      okButtonProps:{style:{visibility:`${okBtnVisible?'visible':'hidden'}`}},
      cancelButtonProps:{style:{visibility:`${cancelBtnVisible?'visible':'hidden'}`}},
      cancelText:"Go to Home Page",
      closable : false,
      keyboard:false,
      onCancel:goToHomePage
    });
    
  }

  function handleInternetChange(isOnline_) {
    setIsOnline(isOnline_); 
  }

  const onRadioChange = (e) => {
    setNotifChannel(e.target.value);
  };
  return (
    <>
      <MyHeader pageTitle="Delete Template" homebtnVisible />

      <h3 className="tempHeading">Enter the Template name to be Deleted:</h3>
      <div className="mainContent">
        <div className="searchPart">
          <div className="cardContainer">
            <Input
              placeholder="Enter Template Name"
              value={tempID}
              onKeyDown={handleEnterPress}
              style={{ borderColor: "black" }}
              onChange={(e) => {
                setTempID(e.target.value);
              }}
            />
            <Radio.Group onChange={onRadioChange} defaultValue={"EMAIL"}>
              <Radio value={"EMAIL"}>Email</Radio>
              <Radio value={"SMS"}>Text Notification</Radio>
            </Radio.Group>

            <Button
              type="primary"
              loading={loading}
              block
              onClick={handleSearch}
            >
            {tempID ? "Search" : "Search all Templates"}
            </Button>
          </div>
        </div>
          <div
            className="noDataImage"
            style={{ display: displayNoDataImage ? "block" : "none" }}
          >
           <div className="example">
         <Spin size="large"/>
         </div>
          </div>
          <div
          className="viewPart "
          style={{
            paddingRight: "2%",
            marginTop:'0',
          }}
        >
        {
            data.length > 0 ? 
            <div >
            
                    
            <Table  
                style={{ display: !displayNoDataImage ? "block" : "none" }}
               
                //  dataSource={dataColumn}
                columns={columns} 
                bordered
                scroll={{y:394}}
                dataSource={data} 
                // pagination={{ pageSizeOptions: [5,10,20,50,100]}}
            />
                    {/* </div>
                  </>
                );
              })} */}
            </div>
            
            : null
          }
        </div>
      </div>

      <Detector
        onChange={(isOnline) => handleInternetChange(isOnline)}
        // render method is necessary
        render={({ online }) => (
          <div></div>
        )}
      />
    </>
  );
}
