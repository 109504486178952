/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Col,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { Option } from "antd/lib/mentions";
import { SyntheticEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CONSTANTS } from "../constants/constants";
import useTriggerAPI from "../hooks/useTriggerAPI";
import { INITIAL_FIELDS } from "../Utils/Fields";
import sortFetchedList from "../Utils/sortFetchedList";
import MyHeader from "../components/Header";
// import no_data_img from "../assets/no_data_1.png";
import "./Styles/UpdateTemplate.css";
import "./Styles/ViewTemplate.css";
import { Detector } from "react-detect-offline";
import { EditFilled, LoadingOutlined } from "@ant-design/icons";
import capitalizeFirstLetter from "../Utils/CapitalizeFirstLetter";
import useGetAllTemplate from "../hooks/useGetAllTemplate";

export default function UpdateTemplate() {
  const nav = useNavigate();
  const location= useLocation();
  const fetchedState: any = location.state;

  useEffect(() => {
    if (window.localStorage.getItem("isAuthenticated") === "false") {
      nav("/");
    }
  }, []);

  useEffect(() => {
    window.history.replaceState({}, document.title)
    getAllTemplates();
  }, []);

  const [tempID, setTempID] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleSubject, setIsModalVisibleSubject] = useState(false);


  const [fetchedTemplatesList, setFetchedTemplatesList] = useState<any[]>([]);
  const [activeState, setActiveState] = useState({});
  const [activeStateSubject, setActiveStateSubject] = useState({});

  const [isAddNewVariableModalOKDisabled, setIsAddNewVariableModalOKDisabled] =
    useState<string>("");

  const [newlyAddedVariable, setNewlyAddedVariable] = useState({
    fieldName: "",
    fieldDescription: "",
    dataType: "",
  });

  const [notifChannel, setNotifChannel] = useState(fetchedState ? fetchedState.notification_channel:"EMAIL");
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [clickedFieldName, setClickedFieldName] = useState("");

  const [version, setVersion] = useState("");
  const [loading, setLoading] = useState(false);

  // This state will hold the value if no changes are made and update is clicked
  // we don't need to add template with new version if there is no change
  const [isAnyFieldClicked, setIsAnyFieldClicked] = useState(false);

  // This state will hold the value if no variable options is selected
  const [isAnyVariableClicked, setIsAnyVariableClicked] = useState(false);
  const [isAnyVariableClickedSubject, setIsAnyVariableClickedSubject] = useState(false);

  const [fields, setFields] = useState(INITIAL_FIELDS);

  const [radioButtonClicked, setRadioButtonClicked] = useState(false);
  const [radioButtonClickedSubject, setRadioButtonClickedSubject] = useState(false);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isEditSubjectModalVisible, setIsEditSubjectModalVisible] =
    useState(false);

  const [displayNoDataImage, setDisplayNoDataImage] = useState(true);

  const { fetchData } = useTriggerAPI();
  const { fetchAllTemplate } = useGetAllTemplate();

  const [spinning, setSpinning] = useState(false);
  const [template, setTemplate] = useState("");

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  const key = 'updatable';
  const [subjectNameInput, setSubjectNameInput] = useState("");
  const [tempNameInput, setTempNameInput] = useState("");

  const [disabled, setDisabled] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [cursorPosition, setCursorPosition] = useState<any>();
  const [fetchedAllTemplatesList, setFetchedAllTemplatesList] = useState<any[]>(
    []
  );
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [viewAllModalVisible, setViewAllModalVisible] = useState(false);

  const toggleDisabled = () => {
    setDisabled(!disabled);
  };
  const toggleEnabled = () => {
    setDisabled(false);
  };
  // to display warning even after the page is refreshed
  if (!isOnline) {
    warning(
      "No Internet Connection",
      "It seems you have lost your internet connection...Please fix and retry!",
      false,
      false
    );
  } else {
    if (!isEditModalVisible) {
      Modal.destroyAll();
    }
  }

  let payload = {
    data: {
      template_id: tempID,
      notification_channel: notifChannel,
      request_type: "list_by_templateid",
      //   version: version,
    },
  };

  const columns = [
    {
      title: 'Template Name',
      dataIndex: 'template_id',
      key: 'template_id',
      align: 'left' as 'left',
      className: 'ant-table-thead ant-table-cell column',
      
    },
    // {
    //   title:'Subject',
    //   dataIndex: 'subject',
    //   key: 'subject',
    //   width: 150,
    //   align: 'center' as 'center',
    //   className: 'column',
    // },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
      width: 150,
      align: 'center' as 'center',
      className: 'column',
    },
    {
      title: 'Latest Created/Updated',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center' as 'center',
      className: 'column',
      render: (obj) => {
        return(getDateAndTime(obj))
      }
    },
    {
      title: 'Update Template',
      key: 'operation',
      width: 150,
      align: 'center' as 'center',
      className: 'column',
      render: (obj) =>{
        return(
          <Tooltip title="Update Template">
          <EditFilled className="modalEditNew"  onClick={() => {
                            onEditAnchorClick(obj);
                          }} style={{ fontSize: "large" }} />
          </Tooltip>
        )
      } 
    },
  ];

  const getDateAndTime = (timestamp: string) => {
    let date = new Date(timestamp);
    return date.toLocaleString('en-US');
  };


  const data = fetchedTemplatesList.map((row, id)=> ({
    template: row.template,
    template_id: row.template_id,
    subject: row.subject,
    version: row.version,
    created_at: getDateAndTime(row.created_at),
    key: id,
    children: row.children,
    notification_channel: row.notification_channel,
  }))

  async function getAllTemplates(){
    try {
    let data = await fetchAllTemplate(notifChannel);
    data = data.sort(sortByTimeStamp);

    setDisplayNoDataImage(false);
    // setPaginationVisible(true);
    // setFetchedAllTemplatesList(data);
    setFetchedTemplatesList(data);
    setLoading(false);
  } catch (error) {
    message.error(CONSTANTS.ERROR_IN_FETCHING);
    
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }
  }

  function sortByTimeStamp(x, y) {
    let date1 = new Date(x.created_at);
    let date2 = new Date(y.created_at);
    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
  }
  
  const addVariable = () => {
    setIsModalVisibleSubject(true);
  };

  const addVariableOk =() => {
    let textBeforeCursorPosition = emailSubject.substring(0, cursorPosition)
    let textAfterCursorPosition = emailSubject.substring(cursorPosition, emailSubject.length)
    setIsModalVisibleSubject(false);
    setEmailSubject(textBeforeCursorPosition + window.localStorage.getItem("code") + textAfterCursorPosition)
  }




  const addNewlyCreatedVariable = () => {
    let newVar = {
      id: fields.length + 1,
      ...newlyAddedVariable,
      fieldCode: `{{${newlyAddedVariable.fieldName.toLowerCase()}}}`,
    };
    if (newlyAddedVariable.fieldName) {
      INITIAL_FIELDS.push(newVar);
    }
    message.info({content:`${newlyAddedVariable.fieldName} Variable Added!`, key});

    //refresh the input fields
    newlyAddedVariable.dataType = "";
    newlyAddedVariable.fieldName = "";
    newlyAddedVariable.fieldDescription = "";

    setIsAddModalVisible(false);
  };

  const handleOkAdd = () => {
    message.config({ maxCount: 0, duration: 1 });
    const regex_emoji =
      /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;

    if (
      regex_emoji.test(newlyAddedVariable.fieldName) ||
      regex_emoji.test(newlyAddedVariable.fieldDescription)
    ) {
      message.warning({content:"Emojis are not allowed!", key});
    } else {
      addNewlyCreatedVariable();
    }
  };

  const handleCancelAdd = () => {
    setIsAddModalVisible(false);
  };

  const openModal = (clickedFieldIndex: string) => {
    setActiveState({
      [clickedFieldIndex]: window.localStorage.getItem("code"),
    });
    setClickedFieldName(clickedFieldIndex);
    setIsModalVisible(true);
    // setIsModalVisibleSubject(true);
  };

  const handleNewVariableInputChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    const name = target.name;
    const value = target.value;
    let temp = "";
    value.trim() ? (temp = "a") : (temp = "");
    setIsAddNewVariableModalOKDisabled(temp);

    setNewlyAddedVariable({ ...newlyAddedVariable, [name]: value });
  };

  const addUpdateFunctionality = () => {

      let doc = document.getElementById("master") as HTMLDivElement;

      //  <div class="myTag"></div>

      let mytag = doc.getElementsByClassName("myTag");

      for (let i = 0; i < mytag.length; i++) {
        let tag = mytag[i] as HTMLDivElement;

        if (tag.firstElementChild?.tagName === undefined) {
          let button = document.createElement("button");
          let div = document.createElement("div");
          button.style.cursor = "pointer";
          button.textContent = "Click to add variable";
          button.addEventListener("click", () => {

            openModal(i.toString());
          });
          div.append(button);
          tag.append(div);
        } else if (i.toString() === Object.keys(activeState)[0]) {

          if (
            tag.firstElementChild?.tagName === "BUTTON" ||
            tag.firstElementChild.tagName === "DIV"
          ) {

            // Here an if condition of checking "if field is clicked or not",
            // is placed in order to make sure that the changes made gets
            // reverted back to original once cancel button is pressed
            if (clickedFieldName) {
              let p = document.createElement("p");
              p.innerText = activeState[i.toString()];
              let div = document.createElement("div");
              p.style.cursor = "pointer";
              p.addEventListener("click", () => {
                openModal(i.toString());
              });
              div.append(p);
              tag.firstElementChild.replaceWith(div);
            }
          }
        } else {
          let div = tag.firstElementChild as HTMLDivElement;
          let p = tag.firstElementChild
            .firstElementChild as HTMLParagraphElement;
          p.style.cursor = "pointer";
          div.firstElementChild?.addEventListener("click", () => {
            openModal(i.toString());
          });
          // tag.firstElementChild.replaceWith(div)
        }
      }
      let elements = ["h1", "h2", "h3", "h4", "h5", "h6", "p"];
      for (let index = 0; index < elements.length; index++) {
        const tags = doc.getElementsByTagName(elements[index]);
        for (let tagIndex = 0; tagIndex < tags.length; tagIndex++) {
          const elemTag = tags[tagIndex] as HTMLElement;

          if (isLetter(elemTag.textContent?.charAt(0))) {
            elemTag.contentEditable = "true";
            elemTag.addEventListener("dragenter", disableDrag);
            elemTag.addEventListener("dragover", disableDrag);
          }

          // this observer will observe for any changes happened in the array of tags
          // so that the update template will be enabled if any changes is made
          const observer = new MutationObserver((mutRecord) => {
            if (mutRecord[0].target) {
              setIsAnyFieldClicked(true);
            }
          });
          observer.observe(elemTag, { characterData: true, subtree: true });
        }
      }
    
    disableUnwantedElements(false);
  };

  function disableDrag(e: any) {
    e.preventDefault();
    e.dataTransfer.dropEffect = "none";
  }

  function isLetter(str) {
    return str.toLowerCase() !== str.toUpperCase();
  }

  function handleEnterPress(e) {
    if (e.key === "Enter") {
      if (isModalVisible) {
        handleOk();
        toggleEnabled();
      } else {
        handleSearch();
           toggleEnabled();
      }
    }
  }
  const handleSearch = async () => {
    message.config({ maxCount: 0, duration: 1 });

    setLoading(true);
    toggleDisabled();
    setFetchedTemplatesList([]);

    if (tempID) {
      payload["data"]["template_id"] = tempID;
      payload["data"]["notification_channel"] = notifChannel;
      try {
      let data = await fetchData(payload);

      if (data.length > 0) {
        setLoading(false);
      }
         else {
          // no data found
          message.error({content:CONSTANTS.TEMPLATE_NOT_FOUND, key});
          setLoading(false);
        }
      
      let tempList = sortFetchedList(data);
      setFetchedTemplatesList(tempList);
      setDisplayNoDataImage(false);
    } catch (error) {
      message.error(CONSTANTS.ERROR_IN_UPDATING);
      setLoading(false);
    }
  }
    
    else {
      setIsSearchClicked(true);
      getAllTemplates();
      // message.warning({content:CONSTANTS.TEMPLATE_NAME, key});
      // setLoading(false);
    }
  };

  const handleCancel = async () => {
    setIsModalVisible(false);
  };

  const handleCancelSubject = async () => {
    setIsModalVisibleSubject(false);
  };


  function info() {
    Modal.info({
      title: "Edit your Template",
      keyboard: true,
      content: (
        <div>
          <p style={{ fontSize: "18px" }}>
            Your template is now editable. Click on the respective fields to
            replace it with the variable of your choice.
          </p>
        </div>
      ),
      onOk() {},
      afterClose() {
        addUpdateFunctionality();
        // disableUnwantedElements();
      },
    });
  }

  function getDataType(fieldCode: string) {
    for (let i = 0; i < fields.length; i++) {
      let fieldItem = fields[i];
      if (fieldItem.fieldCode === fieldCode) {
        return fieldItem.dataType;
      }
    }
    return "string";
  }

  function extractVariablesFromHTML() {
    let result: string[] = [];
    let temp: any = [];
    let doc = document.getElementById("master") as HTMLDivElement;
    let mytags = doc.getElementsByClassName("myTag");
    for (let index = 0; index < mytags.length; index++) {
      let tag = mytags[index] as HTMLDivElement;
      let variableCode = tag.innerText;
      if (variableCode) {
        result.push(variableCode.substring(2, variableCode.length - 2));
      }
    }

    let atags = doc?.getElementsByTagName('a') ?? [];
    for (let index = 0; index < atags.length; index++) {
      const element = atags[index] as HTMLAnchorElement;
      const href = element.getAttribute('href');
      if(href?.startsWith("{{") && href?.endsWith("}}")){
        result.push(href.substring(2,href.length-2));
      }  
      
    }

    result.map((item) => {
      let obj: any = {};

      obj["name"] = item;
      obj["type"] = getDataType(`{{${item}}}`);
      temp.push(obj);
    });

    return temp;
  }

  function disableUnwantedElements(enableLinks: boolean) {
    let doc = document.getElementById("master");
    let tags = ["select", "radio", "input", "textarea", "a"];

    for (let index = 0; index < tags.length; index++) {
      const element = doc?.getElementsByTagName(tags[index]) ?? [];

      for (let j = 0; j < element.length; j++) {
        const elementToBeDisabled = element[j] as any;

        // this is done so as to activate all links once email is saved and is ready to be
        // used as a template
        if (enableLinks && elementToBeDisabled.tagName === "A") {
          elementToBeDisabled.disabled = false;
          elementToBeDisabled.style.cursor = "pointer";
          elementToBeDisabled.style.pointerEvents = "auto";
        } else {

          elementToBeDisabled.disabled = true;
          elementToBeDisabled.style.cursor = "default";
          elementToBeDisabled.style.pointerEvents = "none";
        }
      }
    }
  }

  const removeBtnsFromHTML = () => {
    let doc = document.getElementById("master") as HTMLDivElement;
    let mytags = doc.getElementsByClassName("myTag");
    for (let index = 0; index < mytags.length; index++) {
      let tag = mytags[index] as HTMLDivElement;
      if (tag.firstElementChild?.firstElementChild?.tagName === "BUTTON") {
        tag.removeChild(tag.firstElementChild);
      } else if (tag.firstElementChild?.firstElementChild?.tagName === "DIV") {
        let ptag = tag.firstElementChild
          .firstElementChild as HTMLParagraphElement;
        ptag.style.cursor = "default";
      }
    }

    let elements = ["h1", "h2", "h3", "h4", "h5", "h6", "p"];
    for (let index = 0; index < elements.length; index++) {
      const tags = doc.getElementsByTagName(elements[index]);
      for (let tagIndex = 0; tagIndex < tags.length; tagIndex++) {
        const elemTag = tags[tagIndex] as HTMLElement;
        elemTag.contentEditable = "false";
      }
    }
    let tags = ["select", "radio", "input", "textarea"];

    disableUnwantedElements(true);

    // let linkTags = doc?.getElementsByTagName('a') ?? [];
    // for (let index = 0; index < linkTags.length; index++) {
    //   const element = linkTags[index] as any;
    //   console.log(element);
    //   element.disabled = false;
    //   element.style.pointerEvents = 'auto'
    //   element.style.cursor = 'pointer';
    //   console.log(element);

    // }
  };

  // for sms template
  function extractVariablesFromInput(inputData: string) {
    let result: string[] = [];
    let tokens = inputData.split(" ");
    tokens.forEach((token: string) => {
      // remove all special characters except {}
      let token_ = token.replace(/[&\/\\#,+()$~%.'":*?<>]/g, "");
      if (token_.startsWith("{{") && token_.endsWith("}}")) {
        result.push(token_);
      }
    });

    return result;
  }

  // for sms template
  function getTemplateVariables(smsinput) {
    let result: any = [];
    let fieldCodes: string[] = extractVariablesFromInput(smsinput);

    fieldCodes.map((fieldCode) => {
      let obj: any = {};
      obj["name"] = fieldCode.substring(2, fieldCode.length - 2);
      obj["type"] = getDataType(fieldCode);
      result.push(obj);
    });
    return result;
  }

  async function updateSMSTemplate(smsTemplate) {
    message.config({ maxCount: 0, duration: 1 });

    let updateSMSTemplateObject = {
      data: {
        template_id: tempID.trim(),
        notification_channel: "SMS",
        template_variables: JSON.stringify(getTemplateVariables(smsTemplate)),
        template: smsTemplate,
        request_type: "update",
        version: +version + 1,
       
      },
    };

    let data = await fetchData(updateSMSTemplateObject);

    if (data.length>0) {
      message.success({content:CONSTANTS.TEMPLATE_UPDATED, key});
      setLoading(false);
      setIsEditModalVisible(false);
      let listData = await fetchData(payload);
      setFetchedTemplatesList(sortFetchedList(listData));

      // Here the page is reloading because the updated state is not reflecting the fresh data
      // on reload the states are fetched from scratch and so all changes are seen correctly
      window.location.reload();
    } else {

      message.error({content:"Error in saving the Template!", key});
      setLoading(false);
    }
    setLoading(false);
  }

  async function updateEmailTemplate(_template_, tempVar) {
    message.config({ maxCount: 0, duration: 1 });

    let tempVariables;

    if (notifChannel === "EMAIL") {
      tempVariables = JSON.stringify(
        removeDuplicates(extractVariablesFromHTML())
      );
    } else {
      tempVariables = tempVar;
    }

    let updateTemplateObject = {
      data: {
        template_id: tempID.trim(),
        notification_channel: "EMAIL",
        template_variables: tempVariables,
        template: _template_,
        subject: emailSubject,
        request_type: "update",
        version: +version + 1,
        raw_html:""
      },
    };

    let data = await fetchData(updateTemplateObject);
    // let status= await fetchData(updateTemplateObject);
   
    if (data) {
      message.success({content:CONSTANTS.TEMPLATE_UPDATED, key});
      setLoading(false);
      setIsEditSubjectModalVisible(false);
      setIsEditModalVisible(false);
      window.location.reload();
      let listData = await fetchData(payload);
      setFetchedTemplatesList(sortFetchedList(listData));

      // Here the page is reloading because the updated state is not reflecting the fresh data
      // on reload the states are fetched from scratch and so all changes are seen correctly
    } else {

      message.error({content:"Error in saving the Template!", key});
      setLoading(false);
    }
  }

  function isFieldHeadEmpty() {
    let doc = document.getElementById("master");
    let dyFields = doc?.getElementsByClassName("dyField") ?? [];
    let flag = 0;
    for (let k = 0; k < dyFields.length; k++) {
      const dyElem = dyFields[k];
      if (dyElem.nextElementSibling?.textContent?.trim().length === 0) {
        flag = 1;
      }
    }
    return flag === 1;
  }
  const handleEditModalOk = async () => {
    message.config({ maxCount: 0, duration: 1 });

    if (isFieldHeadEmpty()) {
      message.warning({content:"Field names should not be empty!", key});
    } else {
      disableUnwantedElements(true);
      setIsEditSubjectModalVisible(true);
    }
  };

  function removeDuplicates(arr: any) {
    return arr.filter(
      (value, index, self) =>
        index ===
        self.findIndex((t) => t.place === value.place && t.name === value.name)
    );
  }


  const onEditAnchorClick = async (template) => {
    // here the last version number is added so that the one will be added to it
    let version;
    if (tempID !== ""){
      version = fetchedTemplatesList[0].version;
    }
    else{
      const result = fetchedTemplatesList.filter(item=> item.template_id===template.template_id);
      version = result[0].version;
    }
   //let version = fetchedTemplatesList[0].version;
   //let version = template.version;

    setVersion(version);
    setTempID(template.template_id);

    let template__ ;
    setViewAllModalVisible(true);
     payload['data']['template_id'] = template.template_id ;
     payload['data']['notification_channel'] = template.notification_channel
   // payload['data']['template_id'] = `${template.template_id}#${template.version}`;
    payload['data']['request_type'] = "view" ;
    payload['data']['version'] = template.version;
      try {
        let templateData = await fetchData(payload);
        template__ = templateData[0];
        setViewAllModalVisible(false);
        // templateData.map((data)=>{
        //   if(data.version === template.version){
        //     template__ = data;
        //     setViewAllModalVisible(false);
        //   }
        //   console.log("ddddtaa", data)
        // })
      } catch (error) {
        console.log(error);
        
      }
    if (template.notification_channel === "SMS") {
      nav("/sms", {
        state: {
          payload: template__.template,
          tempID: template__.template_id,
          version: version,
        },
      });
    } else if (template__.raw_html) {
      nav("/wizard", {
        state: {
          payload: template__.raw_html,
          html: template__.template,
          tempID: template__.template_id,
          version: version,
          subject: template__.subject,
          tempVariables: JSON.parse(template__.template_variables),
        },
      });
    }  else if (template__.sourceCode ==="source_code") {
      nav("/source", {
        state: {
          payload: template__.template,
          tempID: template__.template_id,
          version: version,
          subject: template__.subject,
          // tempVariables: JSON.parse(template__.template_variables),
        },
      });
    } else {
      setIsEditModalVisible(true);

      setTemplate(template__.template);
      setEmailSubject(template__.subject);

      //display info modal
      info();
    }
  };

  const handleOk = () => {
    if (radioButtonClicked) {
      setIsAnyFieldClicked(true);
    }

    setIsModalVisible(false);
    addUpdateFunctionality();
  };

  const addNewVariable = () => {
    setIsAddModalVisible(true);
  };

  function goToHomePage() {
    nav("/home");
  }

  function warning(
    title: string,
    content: string,
    okBtnVisible: boolean,
    cancelBtnVisible: boolean
  ) {
    Modal.confirm({
      title: title,
      content: content,
      okButtonProps: {
        style: { visibility: `${okBtnVisible ? "visible" : "hidden"}` },
      },
      cancelButtonProps: {
        style: { visibility: `${cancelBtnVisible ? "visible" : "hidden"}` },
      },
      cancelText: "Go to Home Page",
      closable: false,
      keyboard: false,
      onCancel: goToHomePage,
    });
  }

  function handleInternetChange(isOnline_) {
    setIsOnline(isOnline_);
  }

  const onRadioChange = (e) => {
    setRadioButtonClicked(true);
    window.localStorage.setItem("code", e.target.value.fieldCode);
    window.localStorage.setItem("type", e.target.value.dataType);
    setActiveState({ [clickedFieldName]: e.target.value.fieldCode });

    setIsAnyVariableClicked(true);
  };

  const onRadioChangeSubject = (e) => {
    setRadioButtonClickedSubject(true);
    window.localStorage.setItem("code", e.target.value.fieldCode);
    window.localStorage.setItem("type", e.target.value.dataType);
    setActiveStateSubject({ [clickedFieldName]: e.target.value.fieldCode });

    setIsAnyVariableClickedSubject(true);
  };

  function handleSubjectEnterPress(e) {
    if (e.key === "Enter") {
      if (emailSubject.trim()) handleUpdateSubjectOk();
    }
  }

  const handleUpdateSubjectOk = async () => {
    message.config({ maxCount: 0, duration: 1 });
    const regex_emoji =
      /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;
    if (regex_emoji.test(emailSubject)) {
      message.warning({content:"Emojis are not allowed!", key});
    } else {
      setLoading(true);

      removeBtnsFromHTML();
      
      let mDoc = document.getElementById("master");
      let _template_ = mDoc?.outerHTML;
    
      console.log(_template_);
      updateEmailTemplate(_template_, {});

      // payload["data"]["notification_channel"] = "SMS";
      // let res = await fetchData(payload);

      // updateSMSTemplate(sortFetchedList(res.body)[0].template);
    }
  };

  function handleUpdateCancel() {
    message.config({ maxCount: 0, duration: 1 });
    if (isFieldHeadEmpty()) {
      message.warning({content:"Field names should not be empty!", key});
    } else {
      setIsAnyFieldClicked(false);
      setClickedFieldName("");
      setIsEditModalVisible(false);
    }
  }
  return (
    <>
      <MyHeader pageTitle="Update Template" homebtnVisible />
      <h3 className="tempHeading">Enter the Template name to be Updated:</h3>

      <div className="mainContent">
        <div className="searchPart">
          <div className="cardContainer">
            {/* <Space direction="vertical"> */}
            <Input
              placeholder="Enter Template Name"
             // value={tempID}
              onKeyDown={handleEnterPress}
              style={{ borderColor: "black" }}
              onChange={(e) => {
                setTempID(e.target.value);
                // toggleEnabled();
              }}
            />
            <Radio.Group
              onChange={(e) => setNotifChannel(e.target.value)}
              defaultValue={fetchedState ? fetchedState.notification_channel:"EMAIL"}
            >
              {/* <Radio disabled={disabled} value={"EMAIL"}>Email</Radio>
              <Radio disabled={disabled} value={"SMS"}>Text Notification</Radio> */}
              <Radio value={"EMAIL"}>Email</Radio>
              <Radio  value={"SMS"}>Text Notification</Radio>
            </Radio.Group>

            <Button
              type="primary"
              loading={loading}
              block
              onClick={handleSearch}
            >
              {tempID ? "Search" : "Search all Templates"}
            </Button>
            {/* </Space> */}
          </div>
        </div>
        <div
          className="noDataImage"
          style={{ display: displayNoDataImage ? "block" : "none" }}
        >
         <div className="example">
         <Spin size="large"/>
         </div>
        </div>
        <div
          className="viewPart "
          style={{
            paddingRight: "2%",
            marginTop: "0",
          }}
        >

{
            data.length > 0 ? 
            <div >
            
                    
            <Table  
                style={{ display: !displayNoDataImage ? "block" : "none" }}
               
                //  dataSource={dataColumn}
                columns={columns} 
                bordered
                scroll={{y:394}}
                dataSource={data} 
                // pagination={{ pageSizeOptions: [5,10,20,50,100]}}
            />
                    {/* </div>
                  </>
                );
              })} */}
            </div>
            
            : null
          }
         
        </div>
      </div>

      {/* Modal for displaying the template that is to be edited */}
      <Modal
        title="Edit the Template"
        width={800}
        keyboard={false}
        visible={isEditModalVisible}
        bodyStyle={{ overflow: "scroll" }}
        okText={"Update Template"}
        onOk={handleEditModalOk}
        okButtonProps={{ loading: loading, disabled: !isAnyFieldClicked }}
        onCancel={handleUpdateCancel}
      >
        <Spin spinning={spinning} />
        <div id="master" dangerouslySetInnerHTML={{ __html: template }} />
      </Modal>

      <Modal
        title="Update Email Subject"
        visible={isEditSubjectModalVisible}
        // onOk={handleUpdateSubjectOk}
        closable={false}
        // okButtonProps={{ disabled: !emailSubject.trim(), loading: loading }}
        // // cancelButtonProps={{disabled:saveTempIsLoading}}
        onCancel={() => {
          setIsEditSubjectModalVisible(false);
        }}
        width="400px"
        footer={[
          <Tooltip title="Add a Variable">
          <Button onClick={addVariable}
           disabled= {!emailSubject.trim()}
          >
           Add variable
          </Button>
          </Tooltip>,
          <Button
            key="back"
            onClick={() => {
              setIsEditSubjectModalVisible(false);
            }}
          >
            Cancel
          </Button>,
          <Button type="primary" key="edit" onClick={handleUpdateSubjectOk} 
          disabled= {!emailSubject.trim()}
          loading= {loading}
          >
            Ok
          </Button>, 
        ]}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Input
            placeholder={emailSubject}
            name="tempName"
            id="input"
            onKeyDown={handleSubjectEnterPress}
            value={emailSubject}
            width="100%"
            onChange={(e) => {
              setEmailSubject(e.target.value);
              setCursorPosition(e.target.selectionStart)
            }}

          />
        </Space>
      </Modal>

      <Modal
        className="modContainer"
        title="Select a Variable to Update"
        visible={isModalVisible}
        // onOk={handleOk}
        //   bodyStyle={modalBodyStyle}
        closable={false}
        keyboard
        style={{ top: 10 }}
        mask
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="addField"
            type="primary"
            onClick={handleOk}
            //onClick={addVariableOk}
            disabled={!isAnyVariableClicked}
          >
            Update Variable
          </Button>,
          <Button type="primary" onClick={addNewVariable}>
            Create New Variable
          </Button>,
        ]}
      >
        <Radio.Group onChange={onRadioChange}>
          {fields.map((field) => {
            return (
              <div style={{ display: "block", padding: "4px" }}>
                <Radio
                  value={field}
                  onKeyDown={handleEnterPress}
                  checked={isAnyVariableClicked}
                >
                  <div>
                    <h4>{field.fieldName}</h4>
                  </div>
                </Radio>
              </div>
            );
          })}
        </Radio.Group>
      </Modal>

      <Modal
        className="modContainer"
        title="Select a Variable to Update"
        visible={isModalVisibleSubject}
        // onOk={handleOk}
        //   bodyStyle={modalBodyStyle}
        closable={false}
        keyboard
        style={{ top: 10 }}
        mask
        footer={[
          <Button key="back" onClick={handleCancelSubject}>
            Cancel
          </Button>,
          <Button
            key="addField"
            type="primary"
            //onClick={handleOk}
             onClick={addVariableOk}
            disabled={!isAnyVariableClickedSubject}
          >
            Update Variable
          </Button>,
          <Button type="primary" onClick={addNewVariable}>
            Create New Variable
          </Button>,
        ]}
      >
        <Radio.Group onChange={onRadioChangeSubject}>
          {fields.map((field) => {
            return (
              <div style={{ display: "block", padding: "4px" }}>
                <Radio
                  value={field}
                  onKeyDown={handleEnterPress}
                  checked={isAnyVariableClickedSubject}
                >
                  <div>
                    <h4>{field.fieldName}</h4>
                  </div>
                </Radio>
              </div>
            );
          })}
        </Radio.Group>
      </Modal>

      <div className="modContainer">
        <Modal
          title="Add New Variable"
          visible={isAddModalVisible}
          onOk={handleOkAdd}
          okButtonProps={{ disabled: !isAddNewVariableModalOKDisabled }}
          onCancel={handleCancelAdd}
          width="250px"
        >
          <Space direction="vertical">
            <Input
              placeholder="Field Name"
              name="fieldName"
              value={newlyAddedVariable.fieldName}
              onChange={handleNewVariableInputChange}
            />
            <Input
              placeholder="Field Description"
              name="fieldDescription"
              value={newlyAddedVariable.fieldDescription}
              onChange={handleNewVariableInputChange}
            />

            <Input.Group compact>
              <div className="addbutton">
                <h5>Select Data type :</h5>
              </div>
              <Select
                style={{ width: "100%" }}
                defaultValue="string"
                value={newlyAddedVariable.dataType}
                onChange={(value) =>
                  setNewlyAddedVariable({
                    ...newlyAddedVariable,
                    dataType: value,
                  })
                }
              >
                <Option value="string">String </Option>
                <Option value="number">Number</Option>
                <Option value="boolean">Boolean</Option>
                <Option value="date">Date</Option>
              </Select>
            </Input.Group>
          </Space>
        </Modal>
      </div>
      <Modal
      centered
      footer = {null}
      visible = {viewAllModalVisible}
      closable = {false}
      width={300}
      bodyStyle={{paddingTop:'50px',paddingBottom:'50px',margin:'auto'}}
      >
        <div id="allTempModal" >
          <div><Spin indicator={<LoadingOutlined style={{fontSize:'30px',color:'#EA7327'}}/>} /></div>
       
          <div ><h3>Loading Template...</h3></div>
        </div>
      </Modal>

      <Detector
        onChange={(isOnline) => handleInternetChange(isOnline)}
        // render method is necessary
        render={({ online }) => <div></div>}
      />
    </>
  );
}
