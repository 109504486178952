/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import "./Styles/SelectTemplateOption.css";
import { useNavigate } from "react-router-dom";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, message, Modal, Radio, Select } from "antd";
import MyHeader from "../components/Header";
import { useEffect, useState } from "react";
import { html1 } from "../Utils/TemplateOne";
import { html2 } from "../Utils/TemplateTwo";
import { Detector } from "react-detect-offline";

const { Option } = Select;

export default function SelectTemplateOption() {
  const nav = useNavigate();

  useEffect(() => {
    if (window.localStorage.getItem("isAuthenticated") === "false") {
      nav("/");
    }
  }, []);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTemplateOption, setSelectedTemplateOption] =
    useState("Template 1");
  const [selectedCreateRadioOption, setSelectedCreateRadioOption] =
    useState("");
  const [optionType, setOptionType] = useState("");

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  const key = 'updatable';

  // to display warning even after the page is refreshed
  if (!isOnline) {
    warning(
      "No Internet Connection",
      "It seems you have lost your internet connection...Please fix and retry!",
      false,
      false
    );
  } else {
    Modal.destroyAll();
  }

  const handleCancel = () => {
    setSelectedCreateRadioOption("");
    setOptionType("");
    setIsModalVisible(false);
  };

  function handleDropDownChoose(e: any) {
    console.log(e);

    setSelectedTemplateOption(e);
  }
  function handleRadioClickCreate(e) {
    setSelectedCreateRadioOption(e.target.value);
  }

  const handleOk = (e) => {
    if (e.key === "Escape") {
      setIsModalVisible(false);
      setSelectedTemplateOption("");
      setSelectedCreateRadioOption("");
      setOptionType("");
    } else {
      message.config({
        maxCount: 0,
        duration: 1,
      });
      if (optionType === "create") {
        if (selectedCreateRadioOption) {
          switch (selectedCreateRadioOption) {
            case "sms":
              nav("/sms");
              break;
            case "scratch":
              nav("/wizard");
              break;

            case "source":
              nav("/source");
              break;
          }
        } else {
          message.warning({content:"Select an option ", key});
        }
      } else {
        if (selectedTemplateOption) {
          // if (selectedTemplateOption === "") message.warning("Select a template");
          let html;
          if (selectedTemplateOption === "Template 1") {
            html = html1;
          } else if (selectedTemplateOption === "Template 2") {
            html = html2;
          }
          // else if(selectedTemplateOption === "Template 3"){
          //   html = html3;
          // }
          nav("/createtemplate", {
            state: { html: html },
          });
          setIsModalVisible(false);
        } else {
          message.warning({content:"Select an option ", key});
        }
      }
    }
  };
  function goToHomePage() {
    nav("/home");
  }

  function warning(
    title: string,
    content: string,
    okBtnVisible: boolean,
    cancelBtnVisible: boolean
  ) {
    Modal.confirm({
      title: title,
      content: content,
      okButtonProps: {
        style: { visibility: `${okBtnVisible ? "visible" : "hidden"}` },
      },
      cancelButtonProps: {
        style: { visibility: `${cancelBtnVisible ? "visible" : "hidden"}` },
      },
      cancelText: "Go to Home Page",
      closable: false,
      keyboard: false,
      onCancel: goToHomePage,
    });
  }
  function handleInternetChange(isOnline_) {
    setIsOnline(isOnline_);
  }

  function handleOptionClick(optionType: string) {
    setOptionType(optionType);
    setIsModalVisible(true);
  }

  return (
    <>
      <MyHeader homebtnVisible />
      <div className="dividedContainer">
        <div id="cardComp">
          <Card
            id="cardOne"
            hoverable
            onClick={() => handleOptionClick("create")}
          >
            <a
              href="#"
              id="aCont"
              // onClick={() => nav("/chooseTemplate", { state: "create" })}
              onClick={() => handleOptionClick("create")}
            >
              {/* <Link to="/editTemplate" > */}
              <span>
                <PlusOutlined id="icon" />
              </span>
              <h2>Create new Template</h2>
              {/* </Link> */}
            </a>
          </Card>
          <Card
            id="cardTwo"
            hoverable
            onClick={() => handleOptionClick("choose")}
          >
            <a
              href="#"
              id="aCont"
              // onClick={() => nav("/chooseTemplate", { state: "choose" })}
              onClick={() => handleOptionClick("choose")}
            >
              {/* <Link to="/editTemplate" id="aCont" > */}
              <span>
                <EditOutlined id="icon" />
              </span>
              <h2>Choose from Existing Templates</h2>
            </a>
            {/* </Link> */}
          </Card>
        </div>
        <div className="imageComp">
          <img id="footerImg" src={"https://template-designer-images-bucket.s3.amazonaws.com/delivery.jpg"} alt="" width={900} />
        </div>
      </div>

      {optionType === "choose" ? (
        <Modal
          title="Select a Template"
          visible={isModalVisible}
          // visible={modVisible}
          onOk={handleCancel}
          okText="Cancel"
          okButtonProps={{ type: "default" }}
          cancelText="OK"
          width={550}
          style={{ top: 50 }}
          maskClosable={false}
          closable={false}
          cancelButtonProps={{ type: "primary" }}
          onCancel={handleOk}
        >
          <Select
            defaultValue="Template 1"
            defaultActiveFirstOption
            style={{ width: 500, fontSize: "16px" }}
            onChange={handleDropDownChoose}
          >
            <Option value="Template 1">Template 1</Option>
            <Option value="Template 2">Template 2</Option>
            {/* <Option value="Template 3">Template 3</Option> */}
          </Select>
        </Modal>
      ) : (
        <Modal
          title="Select a Template"
          visible={isModalVisible}
          // visible={modVisible}
          onOk={handleCancel}
          okText="Cancel"
          okButtonProps={{ type: "default" }}
          cancelText="OK"
          width={650}
          maskClosable={false}
          closable={false}
          cancelButtonProps={{ type: "primary" }}
          onCancel={handleOk}
        >
          <Radio.Group
            buttonStyle="solid"
            value={selectedCreateRadioOption}
            style={{
              display: "flex",
              padding: "1%",
              justifyContent: "center",
              gap: "10px",
            }}
            onChange={handleRadioClickCreate}
          >
            <Radio.Button className="radBtn" value="sms">
              Create Text Notification
            </Radio.Button>
            <Radio.Button className="radBtn" value="scratch">
              Create using Wizard
            </Radio.Button>
            <Radio.Button className="radBtn" value="source">
              Create from Source code
            </Radio.Button>
          </Radio.Group>
        </Modal>
      )}

      <Detector
        onChange={(isOnline) => handleInternetChange(isOnline)}
        // render method is necessary
        render={({ online }) => <div></div>}
      />
    </>
  );
}
