export const html2 = `
<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office"
    style="width:100%;font-family:arial, 'helvetica neue', helvetica, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;Margin:0">

<head>
    <meta charset="UTF-8">
    <meta content="width=device-width, initial-scale=1" name="viewport">
    <meta name="x-apple-disable-message-reformatting">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta content="telephone=no" name="format-detection">
    <title>New message</title>
    <!--[if (mso 16)]>
<style type="text/css">
a {text-decoration: none;}
</style>
<![endif]-->
    <!--[if gte mso 9]><style>sup { font-size: 100% !important; }</style><![endif]-->
    <!--[if gte mso 9]>
<xml>
<o:OfficeDocumentSettings>
<o:AllowPNG></o:AllowPNG>
<o:PixelsPerInch>96</o:PixelsPerInch>
</o:OfficeDocumentSettings>
</xml>
<![endif]-->
    <style type="text/css">
        #outlook a {
            padding: 0;
        }

        .ExternalClass {
            width: 100%;
        }

        .ExternalClass,
        .ExternalClass p,
        .ExternalClass span,
        .ExternalClass font,
        .ExternalClass td,
        .ExternalClass div {
            line-height: 100%;
        }

        .es-button {
            mso-style-priority: 100 !important;
            text-decoration: none !important;
        }

        a[x-apple-data-detectors] {
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }

        .es-desk-hidden {
            display: none;
            float: left;
            overflow: hidden;
            width: 0;
            max-height: 0;
            line-height: 0;
            mso-hide: all;
        }

        [data-ogsb] .es-button {
            border-width: 0 !important;
            padding: 10px 20px 10px 20px !important;
        }

        [data-ogsb] .es-button.es-button-1 {
            padding: 10px 20px !important;
        }

        .acertus-logo {
            width: 200px;
            height: 45px;
        }

        @media only screen and (max-width:600px) {
            .acertus-logo {
                width: 140px;

            }

            p,
            ul li,
            ol li,
            a {
                line-height: 150% !important
            }

            h1 {
                line-height: 120%
            }

            h2 {
                font-size: 26px !important;
                line-height: 120%
            }

            h3 {
                font-size: 15px !important;
                line-height: 120%
            }

            .es-header-body h1 a,
            .es-content-body h1 a,
            .es-footer-body h1 a {
                font-size: 30px !important
            }

            .es-header-body h2 a,
            .es-content-body h2 a,
            .es-footer-body h2 a {
                font-size: 26px !important
            }

            .es-header-body h3 a,
            .es-content-body h3 a,
            .es-footer-body h3 a {
                font-size: 15px !important
            }

            .es-menu td a {
                font-size: 12px !important
            }

            .es-header-body p,
            .es-header-body ul li,
            .es-header-body ol li,
            .es-header-body a {
                font-size: 16px !important
            }

            .es-content-body p,
            .es-content-body ul li,
            .es-content-body ol li,
            .es-content-body a {
                font-size: 16px !important
            }

            .es-footer-body p,
            .es-footer-body ul li,
            .es-footer-body ol li,
            .es-footer-body a {
                font-size: 16px !important
            }

            .es-infoblock p,
            .es-infoblock ul li,
            .es-infoblock ol li,
            .es-infoblock a {
                font-size: 12px !important
            }

            *[class="gmail-fix"] {
                display: none !important
            }

            .es-m-txt-c,
            .es-m-txt-c h1,
            .es-m-txt-c h2,
            .es-m-txt-c h3 {
                text-align: center !important
            }

            .es-m-txt-r,
            .es-m-txt-r h1,
            .es-m-txt-r h2,
            .es-m-txt-r h3 {
                text-align: right !important
            }

            .es-m-txt-l,
            .es-m-txt-l h1,
            .es-m-txt-l h2,
            .es-m-txt-l h3 {
                text-align: left !important
            }

            .es-m-txt-r img,
            .es-m-txt-c img,
            .es-m-txt-l img {
                display: inline !important
            }

            .es-button-border {
                display: block !important
            }

            .es-btn-fw {
                border-width: 10px 0px !important;
                text-align: center !important
            }

            .es-adaptive table,
            .es-btn-fw,
            .es-btn-fw-brdr,
            .es-left,
            .es-right {
                width: 100% !important
            }

            .es-content table,
            .es-header table,
            .es-footer table,
            .es-content,
            .es-footer,
            .es-header {
                width: 100% !important;
            }

            .es-adapt-td {
                display: block !important;
                width: 100% !important
            }

            .adapt-img {
                width: 100% !important;
                height: auto !important
            }

            .es-m-p0 {
                padding: 0 !important
            }

            .es-m-p0r {
                padding-right: 0 !important
            }

            .es-m-p0l {
                padding-left: 0 !important
            }

            .es-m-p0t {
                padding-top: 0 !important
            }

            .es-m-p0b {
                padding-bottom: 0 !important
            }

            .es-m-p20b {
                padding-bottom: 20px !important
            }

            .es-mobile-hidden,
            .es-hidden {
                display: none !important
            }

            tr.es-desk-hidden,
            td.es-desk-hidden,
            table.es-desk-hidden {
                width: auto !important;
                overflow: visible !important;
                float: none !important;
                max-height: inherit !important;
                line-height: inherit !important
            }

            tr.es-desk-hidden {
                display: table-row !important
            }

            table.es-desk-hidden {
                display: table !important
            }

            td.es-desk-menu-hidden {
                display: table-cell !important
            }

            .es-menu td {
                width: 1% !important
            }

            table.es-table-not-adapt,
            .esd-block-html table {
                width: auto !important
            }

            table.es-social {
                display: inline-block !important
            }

            table.es-social td {
                display: inline-block !important
            }

            a.es-button,
            button.es-button {
                font-size: 20px !important;
                display: block !important;
                border-left-width: 0px !important;
                border-right-width: 0px !important
            }

            .es-m-p5 {
                padding: 5px !important
            }

            .es-m-p5t {
                padding-top: 5px !important
            }

            .es-m-p5b {
                padding-bottom: 5px !important
            }

            .es-m-p5r {
                padding-right: 5px !important
            }

            .es-m-p5l {
                padding-left: 5px !important
            }

            .es-m-p10 {
                padding: 10px !important
            }

            .es-m-p10t {
                padding-top: 10px !important
            }

            .es-m-p10b {
                padding-bottom: 10px !important
            }

            .es-m-p10r {
                padding-right: 10px !important
            }

            .es-m-p10l {
                padding-left: 10px !important
            }

            .es-m-p15 {
                padding: 15px !important
            }

            .es-m-p15t {
                padding-top: 15px !important
            }

            .es-m-p15b {
                padding-bottom: 15px !important
            }

            .es-m-p15r {
                padding-right: 15px !important
            }

            .es-m-p15l {
                padding-left: 15px !important
            }

            .es-m-p20 {
                padding: 20px !important
            }

            .es-m-p20t {
                padding-top: 20px !important
            }

            .es-m-p20r {
                padding-right: 20px !important
            }

            .es-m-p20l {
                padding-left: 20px !important
            }

            .es-m-p25 {
                padding: 25px !important
            }

            .es-m-p25t {
                padding-top: 25px !important
            }

            .es-m-p25b {
                padding-bottom: 25px !important
            }

            .es-m-p25r {
                padding-right: 25px !important
            }

            .es-m-p25l {
                padding-left: 25px !important
            }

            .es-m-p30 {
                padding: 30px !important
            }

            .es-m-p30t {
                padding-top: 30px !important
            }

            .es-m-p30b {
                padding-bottom: 30px !important
            }

            .es-m-p30r {
                padding-right: 30px !important
            }

            .es-m-p30l {
                padding-left: 30px !important
            }

            .es-m-p35 {
                padding: 35px !important
            }

            .es-m-p35t {
                padding-top: 35px !important
            }

            .es-m-p35b {
                padding-bottom: 35px !important
            }

            .es-m-p35r {
                padding-right: 35px !important
            }

            .es-m-p35l {
                padding-left: 35px !important
            }

            .es-m-p40 {
                padding: 40px !important
            }

            .es-m-p40t {
                padding-top: 40px !important
            }

            .es-m-p40b {
                padding-bottom: 40px !important
            }

            .es-m-p40r {
                padding-right: 40px !important
            }

            .es-m-p40l {
                padding-left: 40px !important
            }

        }
    </style>
</head>

<body
    style="width:100%;font-family:arial, 'helvetica neue', helvetica, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;Margin:0;background-color: white">
    <div class="es-wrapper-color">
        <!--[if gte mso 9]>
<v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
<v:fill type="tile" color="#efefef"></v:fill>
</v:background>
<![endif]-->
        <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"
            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:100%;background-repeat:repeat;background-position:center top;background-color: white">
            <tr style="border-collapse:collapse">
                <td valign="top" style="padding:0;Margin:0">
                    <table cellpadding="0" cellspacing="0" class="es-header" align="left"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top">
                        <tr style="border-collapse:collapse">
                            <td align="center" bgcolor="#ffffff" style="padding:0;Margin:0;background-color:#FFFFFF">
                                <table bgcolor="#ffffff" class="es-header-body" align="left" cellpadding="0"
                                    cellspacing="0"
                                    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#FFFFFF;width:600px">
                                    <tr style="border-collapse:collapse">
                                        <td align="left" style="padding:10px 0px 2px 2px;Margin:0">
                                            <!--[if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr><td style="width:181px" valign="top"><![endif]-->


                                            <!--[if mso]></td></tr></table><![endif]-->
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table cellpadding="0" cellspacing="0" class="es-header" align="center"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top">
                        <tr style="border-collapse:collapse">
                            <td align="center" style="padding:0;Margin:0;">
                                <table class="es-header-body" align="center" cellpadding="0" cellspacing="0"
                                    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent;width:600px">
                                    <tr style="border-collapse:collapse">
                                        <td align="left"
                                            style="Margin:0;padding-top:5px;padding-bottom:5px;">
                                            <table cellpadding="0" cellspacing="0" width="100%"
                                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                                <tr style="border-collapse:collapse">
                                                    <td align="center" valign="top"
                                                        style="padding:0;Margin:0;width:560px">
                                                        <table cellpadding="0" cellspacing="0" width="100%"
                                                            role="presentation"
                                                            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                                            <tr style="border-collapse:collapse">
                                                                <td align="center" style="padding:0;Margin:0">
                                                                    <table cellpadding="0" cellspacing="0"
                                                                        class="es-header" align="center"
                                                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top">
                                                                        <tr style="border-collapse:collapse">
                                                                            <td align="center" bgcolor="#ffffff"
                                                                                style="padding:0;Margin:0;background-color:#FFFFFF">
                                                                                <table bgcolor="#ffffff"
                                                                                    class="es-header-body"
                                                                                    align="center" cellpadding="0"
                                                                                    cellspacing="0" role="presentation"
                                                                                    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#FFFFFF;width:580px">
                                                                                    <tr
                                                                                        style="border-collapse:collapse">
                                                                                        <td align="left"
                                                                                            style="padding:20px;Margin:0">
                                                                                            <table cellpadding="0"
                                                                                                cellspacing="0"
                                                                                                role="presentation"
                                                                                                align="left"
                                                                                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                                                                                <tr
                                                                                                    style="border-collapse:collapse">
                                                                                                    <td class="es-m-p0r"
                                                                                                        align="center"
                                                                                                        style="padding:2px;Margin:0;width:161px">
                                                                                                        <img height="30"
                                                                                                            width="30"
                                                                                                            style="width:30%;max-width:30px;height:auto;"
                                                                                                            src="https://acertus-images.s3.amazonaws.com/cart-active.png">
                                                                                                    </td>
                                                                                                    <td class="es-m-p0r"
                                                                                                        align="center"
                                                                                                        style="padding:2px;Margin:0;width:161px">
                                                                                                        <img height="30"
                                                                                                            width="50"
                                                                                                            style="width:50%;max-width:50px;height:auto;"
                                                                                                            src="https://acertus-images.s3.amazonaws.com/car-default.png">
                                                                                                    </td>
                                                                                                    <td class="es-m-p0r"
                                                                                                        align="center"
                                                                                                        style="padding:2px;Margin:0;width:161px">
                                                                                                        <img height="30" width="30" style="width:30%;max-width:30px;height:auto" src="https://acertus-images.s3.amazonaws.com/home-default.png" class="CToWUd">
                                                                                                    </td>
																									 <td class="es-m-p0r"
                                                                                                        align="center"
                                                                                                        style="padding:2px;Margin:0;width:161px">
                                                                                                        <img height="30"
                                                                                                            width="30"
                                                                                                            style="width:50%;max-width:50px;height:auto;"
                                                                                                            src="https://acertus-images.s3.amazonaws.com/delivery-cancelled.png">
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>

                                                                                            <table cellpadding="0"
                                                                                                cellspacing="0"
                                                                                                role="presentation"
                                                                                                align="left"
                                                                                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                                                                                <tr
                                                                                                    style="border-collapse:collapse">
                                                                                                   <td align="center"
                                                                                                        style="padding:2px;Margin:0;width:161px">
                                                                                                        <h5
                                                                                                            style="color: #ffffff;
                                                                                                                            background: #EE7B0F;
                                                                                                                            padding: 7px;
                                                                                                                            font-size: 14px;text-align: center;">
                                                                                                            Recieved
                                                                                                        </h5>
                                                                                                    </td>
                                                                                                    <td align="center"
                                                                                                        style="padding:2px;Margin:0;width:161px">
                                                                                                        <h5
                                                                                                            style="color: #A6A6A6;
                                                                                                                            background: #f1f1f1;
                                                                                                                            padding: 7px;
                                                                                                                            font-size: 14px;text-align: center;">
                                                                                                            Shipped</h5>
                                                                                                    </td>
																									<my-tag></my-tag>
                                                                                                    <td align="center"
                                                                                                        style="padding:2px;Margin:0;width:161px">
                                                                                                        <h5
                                                                                                            style="color: #A6A6A6;
                                                                                                                            background: #f1f1f1;
                                                                                                                            padding: 7px;
                                                                                                                            font-size: 14px;text-align: center;">
                                                                                                            Delivered
                                                                                                        </h5>
                                                                                                    </td>
																									    <td align="center"
                                                                                                        style="padding:2px;Margin:0;width:161px">
                                                                                                        <h5
                                                                                                            style="color: #A6A6A6;
                                                                                                                            background: #f1f1f1;
                                                                                                                            padding: 7px;
                                                                                                                            font-size: 14px;text-align: center;">
                                                                                                            Cancelled
                                                                                                        </h5>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>

                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="es-content" cellspacing="0" cellpadding="0" align="center"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%">
                        <tr style="border-collapse:collapse">
                            <td align="center" bgcolor="#ffffff" style="padding:0;Margin:0;background-color:#FFFFFF">
                                <table class="es-content-body"
                                    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#FFFFFF;width:580px"
                                    cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center">
                                    <tr style="border-collapse:collapse">
                                        <td align="left" style="padding:20px;Margin:0;padding-top:0px">
                                            <table width="100%" cellspacing="0" cellpadding="0"
                                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                                <tr style="border-collapse:collapse">
                                                    <td class="es-m-p0r" valign="top" align="center"
                                                        style="padding:0;Margin:0;width:580px">
                                                        <table width="100%" cellspacing="0" cellpadding="0"
                                                            role="presentation"
                                                            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                                            <tr style="border-collapse:collapse">
                                                                <td align="center" class="es-m-txt-l"
                                                                    style="padding:0;Margin:0;padding-bottom:10px">
                                                                    <h1
                                                                        style="Margin:0;line-height:36px;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-size:30px;font-style:normal;font-weight:normal;color:#666666">
                                                                        Your order has been Received!</h1>
                                                                </td>
                                                            </tr>
                                                            <tr style="border-collapse:collapse">
                                                                <td class="es-m-p0r" valign="top" align="center">
                                                                    <table width="74%" cellspacing="0" cellpadding="0"
                                                                        role="presentation"
                                                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;width:70%">

                                                                        <tr style="border-collapse:collapse">
                                                                            <td align="left" class="es-m-txt-l"
                                                                                style="padding:0;Margin:0;padding-bottom:10px">
                                                                                <table width="100%" cellspacing="0"
                                                                                    cellpadding="0" role="presentation"
                                                                                    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                                                                    <tr
                                                                                        style="border-collapse:collapse">
                                                                                        <td align="left"
                                                                                            style="padding:0;Margin:0;">
                                                                                            <p
                                                                                                style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;color:#666666;font-size:12px;word-break: break-all;">
                                                                                              
                                                                                               
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr style="border-collapse:collapse">
                                        <td class="esdev-adapt-off" align="left"
                                            style="padding:0;Margin:0;padding-left:20px;padding-right:20px">
                                            <table cellpadding="0" cellspacing="0" class="esdev-mso-table"
                                                align="center" bgcolor="#efefef"
                                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#EFEFEF;width:600px">
                                               
                                                <tr style="border-collapse:collapse">
                                                    <td align="left"
                                                        style="padding:0;Margin:0;padding-top:20px;padding-left:20px;padding-right:20px">

                                                        <table class="es-left" cellspacing="0" cellpadding="0"
                                                            align="left"
                                                            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">

                                                            <tr style="border-collapse:collapse">
                                                                <td valign="top" align="center"
                                                                    style="padding:0;Margin:0;width:230px">
                                                                    <table
                                                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-position:center top;word-break: break-all;"
                                                                        width="100%" cellspacing="0" cellpadding="0"
                                                                        role="presentation">
                                                                        <tr style="border-collapse:collapse">
                                                                            <td align="left"
                                                                                style="padding:0;Margin:0;padding-bottom:10px">
                                                                                <div class="dyField"></div>
                                                                                <h4
                                                                                    style="Margin:0;line-height:25px;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#333333;font-size:16px">
                                                                                    VIN:</h4>
																					 <div class="myTag"></div>
                                                                                <p
                                                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;color:#666666;font-size:12px">
                                                                                    
                                                                                </p>
                                                                            </td>
                                                                        </tr>
																		<tr style="border-collapse:collapse">
                                                                            <td align="left"
                                                                                style="padding:0;Margin:0;padding-top:-10px">
                                                                                <h1
                                                                                    style="Margin:0;line-height:2px;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:darkorange;font-size:2px">
                                                                                    
                                                                                </h1>
                                                                            </td>
                                                                        </tr>
                                                                        <tr style="border-collapse:collapse">
                                                                            <td align="left"
                                                                                style="padding:0;Margin:0;padding-top:5px;padding-bottom:10px">
                                                                                <div class="dyField"></div>
                                                                                <h4
                                                                                    style="Margin:0;line-height:25px;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#333333;font-size:16px">
                                                                                    Origin:</h4>
																					 <div class="myTag"></div>
                                                                                <p
                                                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;color:#666666;font-size:12px;word-break: break-all;">
                                                                                    
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                        
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>

                                                        <table class="es-right" cellspacing="0" cellpadding="0"
                                                            align="right"
                                                            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right;margin-bottom: 10px;">
                                                            <tr style="border-collapse:collapse">
                                                                <td align="left" style="padding:0;Margin:0;width:270px">
                                                                    <table
                                                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;"
                                                                        width="100%" cellspacing="0" cellpadding="0"
                                                                        role="presentation">
                                                                        <tr style="border-collapse:collapse">
                                                                            <td align="left" style="padding:0;Margin:0">
                                                                            <div class="dyField"></div>
                                                                                <h2
                                                                                    style="Margin:0;line-height:25px;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:darkorange;font-size:21px">
                                                                                    Order Status:</h2>
																					 <div class="myTag"></div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr style="border-collapse:collapse">
                                                                            <td align="left"
                                                                                style="padding:0;Margin:0;">
                                                                                <h1
                                                                                    style="Margin:0;line-height:25px;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:darkorange;font-size:21px">
                                                                                    <br>
																					
                                                                                </h1>
                                                                            </td>
                                                                        </tr>
																		<tr style="border-collapse:collapse">
                                                                            <td align="left"
                                                                                style="padding:0;Margin:0;padding-top:5px;padding-bottom:5px">
                                                                                <div class="dyField"></div>
                                                                                <h4
                                                                                    style="Margin:0;line-height:25px;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#333333;font-size:16px">
                                                                                    Destination:</h4>
																					 <div class="myTag"></div>
                                                                                <p
                                                                                    style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;color:#666666;font-size:12px;word-break: break-all;">
                                                                                    
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>

                                                    </td>
                                                </tr>

                                            </table>
                                        </td>
                                    </tr>

                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="es-content" cellspacing="0" cellpadding="0" align="center"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%">
                        <tr style="border-collapse:collapse">
                            <td align="center" bgcolor="#ffffff" style="padding:0;Margin:0;background-color:#FFFFFF">
                                <table class="es-content-body"
                                    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#FFFFFF;width:600px"
                                    cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center">
                                    <tr style="border-collapse:collapse">
                                        <td align="left" style="padding:0;Margin:0;padding-left:20px;padding-top:15px">
                                            <table width="100%" cellspacing="0" cellpadding="0"
                                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                                <tr style="border-collapse:collapse">
                                                    <td class="es-m-p0r" valign="top" align="center"
                                                        style="padding:0;Margin:0;width:580px">
                                                        <table width="100%" cellspacing="0" cellpadding="0"
                                                            role="presentation"
                                                            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                                            <tr style="border-collapse:collapse">
                                                                <td align="center" class="es-m-txt-l"
                                                                    style="padding:0;Margin:0;padding-bottom:10px">
                                                                    <h4
                                                                        style="Margin:0;line-height:20px;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-size:18px;font-style:normal;font-weight:normal;color:#666666;font-weight: 500;">
                                                                        Need Assistance?</h4>
                                                                    <p
                                                                        style="word-break: break-word;color: #3C3C3C;margin-bottom: 0;font-size: 14px;">
                                                                        Call 314-334-3400 to speak with our
                                                                        representative.
                                                                    </p>
                                                                    <p
                                                                        style="color: #3C3C3C;margin-bottom: 0;font-size: 14px;">
                                                                        <a id="unsubscribe-btn"
                                                                            style="color: orange;cursor: pointer;"
                                                                            ses:no-track href="{{unsubscribe_url}}"
                                                                            title="Click to un-subscribe from email."
                                                                            target="_blank">Click here</a> to
                                                                        Unsubscribe from Emails.
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>


                                </table>
                            </td>
                        </tr>
                    </table>
                    <table cellpadding="0" cellspacing="0" class="es-footer" align="center"
                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top;margin-top: 3%;">

                        <tr style="border-collapse:collapse">
                            <td align="center" style="padding:0;Margin:0">
                                <table class="es-footer-body"
                                    style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;width:100%;    background-color: rgb(47, 47 ,64);"
                                    cellspacing="0" cellpadding="0" align="center">
                                    <tr style="border-collapse:collapse">
                                        <td valign="top" align="left"
                                            style="padding:0;Margin:0;width:270px;padding-top: 30px;padding-left: 20px;">
                                            <img width="130" src="https://acertus-images.s3.amazonaws.com/acertus_tag.png" class="CToWUd">
                                        </td>
                                    </tr>
                                    <tr style="border-collapse:collapse">
                                        <td align="left"
                                            style="padding:0;Margin:0;padding-bottom:30px;padding-left:24px;padding-right:20px">
                                            <!--[if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr><td style="width:270px" valign="top"><![endif]-->
                                            <table class="es-left" cellspacing="0" cellpadding="0" align="left"
                                                style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                                                <tr style="border-collapse:collapse">
                                                    <td align="left" style="padding:0;Margin:0;width:270px">
                                                        <table width="100%" cellspacing="0" cellpadding="0"
                                                            role="presentation"
                                                            style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                                            <tr style="border-collapse:collapse">
                                                                <td align="left"
                                                                    style="padding:0;Margin:0;padding-top:20px;font-size:0px">
                                                                    <table class="es-table-not-adapt es-social"
                                                                        cellspacing="0" cellpadding="0"
                                                                        role="presentation"
                                                                        style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                                                        <tr style="border-collapse:collapse">
                                                                            <td valign="top" align="center"
                                                                                style="padding:0;Margin:0;padding-right:5px">
                                                                                <a target="_blank" title="Facebook"
                                                                                    ses:no-track
                                                                                    href="https://m.facebook.com/ACERTUSDelivers/"
                                                                                    style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px">
                                                                                   <img width="20" alt="facebook" style="display:block;border:0;outline:none;text-decoration:none;background:rgb(47,47,64)" src="https://ci5.googleusercontent.com/proxy/4b5fnf16vJcOrEyElfVSLrh_0gaqMbrSIzUOMeUPz2o4QsqC-2vTT25tX-rrgq-U6ruN0hSh0Dqj7j3khPtg1CqBIaputC0Ut_8i_q-u=s0-d-e1-ft#https://stg.notifications.vinlocity.io/assets/facebook4.png" class="CToWUd">
                                                                                </a>
                                                                            </td>
                                                                            <td valign="top" align="center"
                                                                                style="padding:0;Margin:0;padding-right:10px">
                                                                                <a target="_blank" ses:no-track
                                                                                    href="https://www.linkedin.com/company/acertusdelivers"
                                                                                    style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px">
																					<img title="Linkedin" src="https://ci3.googleusercontent.com/proxy/T2YVtgGS_LTTojfyaeMl7kP3ySJDuYyX7NeRGeNukNoA5E988C0r1ootWMsMSQkxMF8Oocc_XoYxWW1P1i3Aluxyu803dhtaciLl_2Y=s0-d-e1-ft#https://stg.notifications.vinlocity.io/assets/linkedin.png" alt="linked in" width="20" style="display:block;border:0;outline:none;text-decoration:none" class="CToWUd"></a>
                                                                            </td>
                                                                            <td valign="top" align="center"
                                                                                style="padding:0;Margin:0;    padding-top: 4px;padding-right: 6px;">
                                                                                <a target="_blank" ses:no-track
                                                                                    href="https://youtube.com/channel/UCFKM2nMvFD2oQ3RJvahsIng"
                                                                                    style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#333333;font-size:14px"><img title="Youtube" src="https://ci4.googleusercontent.com/proxy/Rk8GwXEXzEgFqLI-pwfD6ZBkDD2Ck462JFXARqzq8ubTkLxQ4uQFtQo4vc-BXLMNgenWmQ0F0h98JRaApqgIETlhFiWWx3axctoswA=s0-d-e1-ft#https://stg.notifications.vinlocity.io/assets/youtube.png" alt="Yt" width="20" style="display:block;border:0;outline:none;text-decoration:none" class="CToWUd"></a>
                                                                            </td>

                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>

                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>

                </td>
            </tr>
        </table>
    </div>
</body>

</html>
`;
