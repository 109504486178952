/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "./Styles/SmsTemplate.css";
import { Button, Input, message, Modal } from "antd";
import { useEffect, useState } from "react";
import useTriggerAPI from "../hooks/useTriggerAPI";
import { INITIAL_FIELDS } from "../Utils/Fields";
import MyHeader from "../components/Header";
import SearchField from "../components/SearchField";
import { useLocation, useNavigate } from "react-router-dom";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";
import Footer from "../components/Footer";
import { Detector } from "react-detect-offline";
import { CONSTANTS } from "../constants/constants";


export default function SMSTemplate() {
  const nav = useNavigate();
  const location = useLocation();
  const fetchedState: any = location.state;

  useEffect(() => {
    if (window.localStorage.getItem("isAuthenticated") === "false") {
      nav("/");
    }
  }, []);

  const [textAreaInput, setTextAreaInput] = useState(
    fetchedState ? fetchedState.payload : ""
  );
  const [payloadCopy, setPayloadCopy] = useState(
    fetchedState ? fetchedState.payload : ""
  );
  const [isSaveModalVisible, setSaveModalVisible] = useState(false);
  const [okLoading, setOkLoading] = useState(false);
  const [textAreaHasErrors, setTextAreaHasErrors] = useState(true);
  const [tempID, setTempID] = useState("");
  const [loading, setLoading] = useState(false);
  const [version, setVersion] = useState(
    fetchedState ? fetchedState.version : ""
  );
  const [textHead, setTextHead] = useState(fetchedState ? "Update" : "Design");
  const [btnTextHead, setBtnTextHead] = useState(
    fetchedState ? "Update" : "Save"
  );
  const [smsTemplate, setSMSTemplate] = useState("");
  const [variables, setVariables] = useState<string[]>([]);

  // the value of fetchedState was converting to null after page was loaded
  // so it is stored here
  const [stateValue, setStateValue] = useState(fetchedState);

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  const key = 'updatable';

const successMessage = () => {
  setTimeout(() => {
    message.success({ content: 'Text Notification Created!', key, duration: 1.5 });
  }, 1000);
  setOkLoading(false);
  setSaveModalVisible(false);
  nav("/home");
};


  // to display warning even after the page is refreshed
  if (!isOnline) {
    warning(
      "No Internet Connection",
      "It seems you have lost your internet connection...Please fix and retry!",
      "OK",
      false,
      false
    );
  } else {
    Modal.destroyAll();
  }
  const { fetchData } = useTriggerAPI();

  let createSMSTemplateObject = {
    data: {
      template_id: tempID,
      notification_channel: "SMS",
      template_variables: {},
      template: textAreaInput,
      request_type: "create",
      version: 1,
    },
  };

  async function updateSMSTemplate() {
    try{
    let updateSMSTemplateObject = {
      data: {
        template_id: stateValue.tempID,
        notification_channel: "SMS",
        template_variables: JSON.stringify(getTemplateVariables()),
        template: textAreaInput,
        request_type: "update",
        //TODO: get version from location
        version: +version + 1,
        raw_html:""
      },
    };

    let data = await fetchData(updateSMSTemplateObject);

    if (data.status===200) {
      message.success("Template Updated");
      setLoading(false);
      nav("/updatetemplate",{state:{notification_channel:"SMS"}});
    } 
  }catch (error) {
     

      message.error("Error in updating the Template!");
      setLoading(false);
    }
    setLoading(false);
  }

  function getDataType(fieldCode: string) {

    for (let i = 0; i < INITIAL_FIELDS.length; i++) {
      let fieldItem = INITIAL_FIELDS[i];
      //   console.log(fieldItem);

      if (fieldItem.fieldCode === fieldCode) {
        return fieldItem.dataType;
      }
    }
  }

  function getCurlyBracketsCount(inputString: string) {
    let count = 0;
    for (var i = 0; i < inputString.length; i++) {
      let braces = inputString[i];
      if (braces === "{" || braces === "}") count++;
    }
    return count;
  }

  function extractVariablesFromInput(inputData: string) {
    let result: string[] = [];
    let tokens = inputData.replace("\n", " ").split(" ");
    let token_;
    tokens.forEach((token: string) => {
      if (token.split("\n").length > 1) {
        token_ = token.split("\n")[0].replace(/[&\/\\#,+()$~%.'":*?<>]/g, "");
      } else {
        token_ = token.replace(/[&\/\\#,+()$~%.'":*?<>]/g, "");
      }
      if (token_.startsWith("{{") && token_.endsWith("}}")) {

        if (getCurlyBracketsCount(token_) === 4) {
          result.push(token_);
        }
      }
    });
    return result;
  }

  function getTemplateVariables() {
    let result: any = [];
    let fieldCodes: string[] = extractVariablesFromInput(textAreaInput);

    fieldCodes.map((fieldCode) => {

      let obj: any = {};
      obj["name"] = fieldCode.substring(2, fieldCode.length - 2);
      obj["type"] = getDataType(fieldCode) ?? "string"; // if datatype is undefined then we take string as default
      result.push(obj);
    });

    return result;
  }

  function handleEnterPress(e) {
    if (e.key === "Enter") {
      if (tempID.trim()) {
        handleModalOK();
      }
    }
  }

  async function handleSaveBtn() {
    
    if (textAreaHasErrors) {
      error();
    } else {

      if (stateValue) {
        setLoading(true);
        updateSMSTemplate();
      } else {
        setSaveModalVisible(true);
      }


    }

   

    // createSMSTemplateObject['data']['template_variables'] = JSON.stringify()
  }

  async function saveSMSTemplateToDB() {
    setOkLoading(true);
    createSMSTemplateObject["data"]["template_variables"] = JSON.stringify(
      getTemplateVariables()
    );
    // createSMSTemplateObject["data"]["template"] = textAreaInput;


    try {
      let data = await fetchData(createSMSTemplateObject);

      if (data.status === 201) {
        successMessage();
      } 
    } catch (error: any) {
      setOkLoading(false);
      if (error.response.status===409) {
        message.error(CONSTANTS.SAME_TEMPLATE_NAME, 2);
        setOkLoading(false);
      } else {
        message.error({content:"Error in Creating Template", key});
        setOkLoading(false);
      }
      setOkLoading(false);
    }
  }

  async function handleModalOK() {
    message.config({ maxCount: 0, duration: 1 });
    const regex_emoji =
      /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;
    if (regex_emoji.test(tempID.trim())) {
      message.warning("Emojis are not allowed!");
    } else {
      saveSMSTemplateToDB();
    }
  }
  function goToHomePage() {
    nav("/home");
  }

  function handleEditorChange(value: string) {
    let tokens = value.replace("\n", " ").split(" ");
    let flag = 0;
    for (let index = 0; index < tokens.length; index++) {
      let token = tokens[index];

      if (token.split("\n").length > 1) {
        token = token.split("\n")[0].replace(/[&\/\\#,+()$~%.'":*?<>]/g, "");
      } else {
        token = token.replace(/[&\/\\#,+()$~%.'":*?<>]/g, "");
      }

      if (
        (token.startsWith("{{") || token.endsWith("}}")) &&
        getCurlyBracketsCount(token) !== 4
      ) {
        flag = 1;
        break;
        // message.config({ maxCount: 0, duration: 1.5 });

        // message.warning('There are errors in your template. Please Fix it. ')
      }
    }

    if (flag === 1) {
      setTextAreaHasErrors(true);
    } else {
      setTextAreaHasErrors(false);
    }
    setTextAreaInput(value);
  }

  function warning(
    title: string,
    content: string,
    okText: string,
    okBtnVisible: boolean,
    cancelBtnVisible: boolean
  ) {
    Modal.confirm({
      title: title,
      content: content,
      okButtonProps: {
        style: { visibility: `${okBtnVisible ? "visible" : "hidden"}` },
      },
      cancelButtonProps: {
        style: { visibility: `${cancelBtnVisible ? "visible" : "hidden"}` },
      },
      okText: okText,
      cancelText: "Exit to homepage",
      closable: false,
      keyboard: false,
      onCancel: goToHomePage,
    });
  }
  function handleHomeButtonClick() {
    if (textAreaInput) {
      warning(
        "Save Template",
        "Do you want to Exit to homepage or Continue ?",
        "Continue",
        true,
        true
      );
    } else {
      goToHomePage();
    }
  }
  function handleInternetChange(isOnline_) {
    setIsOnline(isOnline_);
  }

  let errorHints = ` 
  It can be because there is:
  <ul>
  <li>No space between two variables</li>
  <li>More than two curly braces at the start or at the end</li>
</ul> `;

  let hints = `How to create a perfect template:
   1. Start by writing some messages
   2. Copy variable from right menu and paste it on the editor
   3. Click on Save template `;

  function error() {
    Modal.error({
      title: "There are errors in your template. Please Fix it.",
      content: <div dangerouslySetInnerHTML={{ __html: errorHints }} />,
    });
  }

  const hightlightWithLineNumbers = (input, language) =>
    highlight(input, language)
      .split("\n")
      .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
      .join("\n");

  return (
    <>
      <MyHeader homebtnVisible handleParentClick={handleHomeButtonClick} />
      <div className="smsContainer">
        <div className="smscontent">
          <div className="firstpart">
            <h2 className="smsHeader">{`${textHead} your Text Notification Template`}</h2>
            <div id="textAreaSms">
              <Editor
                value={textAreaInput}
                onValueChange={handleEditorChange}
                highlight={(value) =>
                  hightlightWithLineNumbers(value, languages.html)
                }
                padding={10}
                textareaId="codeArea"
                // id="textAreaSource"
                placeholder={hints}
                className="editor"
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 14,
                  outline: 0,
                }}
              />
            </div>
            <Button
              type="primary"
              disabled={!textAreaInput || textAreaInput.trim() === payloadCopy}
              loading={loading}
              onClick={handleSaveBtn}
              className="saveTempBtn"
            >
              {`${btnTextHead} Text Notification`}
            </Button>
          </div>
          <div className="secondpart">
            <SearchField height="375px" />
          </div>
        </div>
        <Modal
          title="Save Template as"
          onOk={handleModalOK}
          okText="Save"
          okButtonProps={{ loading: okLoading, disabled: !tempID.trim() }}
          cancelButtonProps={{ disabled: okLoading }}
          onCancel={() => setSaveModalVisible(false)}
          visible={isSaveModalVisible}
          width="400px"
        >
          <Input
            placeholder="Enter template Name"
            value={tempID}
            onKeyDown={handleEnterPress}
            onChange={(e) => {
              setTempID(e.target.value);
            }}
            width="100%"
          />
        </Modal>
      </div>
      <Footer />

      <Detector
        onChange={(isOnline) => handleInternetChange(isOnline)}
        // render method is necessary
        render={({ online }) => <div></div>}
      />
    </>
  );
}
