export let INITIAL_FIELDS = [
    {
      id: 1,
      fieldName: "First Name",
      fieldDescription: "Customer's first name",
      fieldCode: "{{fname}}",
      dataType: "string",
    },
    {
      id: 2,
      fieldName: "Last Name",
      fieldDescription: "Customer's last name",
      fieldCode: "{{lname}}",
      dataType: "string",
    },
    {
      id: 3,
      fieldName: "Vinlocity ID",
      fieldDescription: "Vinlocity ID",
      fieldCode: "{{vinlocity_id}}",
      dataType: "string",
    },
    {
      id: 4,
      fieldName: "Order Status",
      fieldDescription: "Order Status. e.g. Delivered, Confirmed, Received",
      fieldCode: "{{status}}",
      dataType: "string",
    },
    {
      id: 5,
      fieldName: "Origin Address Line 1",
      fieldDescription: "Origin Address Line 1",
      fieldCode: "{{o_line_1}}",
      dataType: "string",
    },
  
    {
      id: 6,
      fieldName: "Destination Address Line 1",
      fieldDescription: "Destination Address Line 1",
      fieldCode: "{{d_line_1}}",
      dataType: "string",
    },
    {
      id: 7,
      fieldName: "Estimated Delivery Date",
      fieldDescription: "Estimated Date of Delivery Date",
      fieldCode: "{{estimated_delivery_date}}",
      dataType: "string",
    },
  ];