/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Modal,
  Button,
  Radio,
  Input,
  Select,
  Space,
  message,
  Tooltip,
} from "antd";
import "./Styles/CreateFromExisting.css";
import { INITIAL_FIELDS } from "../Utils/Fields";

import { SyntheticEvent, useEffect, useState } from "react";
import { Option } from "antd/lib/mentions";
import { SaveOutlined } from "@ant-design/icons";
import axios from "axios";
import axiosRetry from "axios-retry";
import { Detector } from "react-detect-offline";
import MyHeader from "../components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { CONSTANTS } from "../constants/constants";
import useTriggerAPI from "../hooks/useTriggerAPI";

export default function CreateFromExisting() {
  const location = useLocation();
  const nav = useNavigate();

  useEffect(() => {
    if (window.localStorage.getItem("isAuthenticated") === "false") {
      nav("/");
    }
  }, []);
  let fetchedState: any = location.state;
  let html = fetchedState.html;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fields, setFields] = useState(INITIAL_FIELDS);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const [isTemplateSaved, setIsTemplateSaved] = useState(false);
  const [isAnyVariableClicked, setIsAnyVariableClicked] = useState(false);
  const [isTempNameInputDisabled, setIsTempNameInputDisabled] = useState(false);
  const [tempNameInput, setTempNameInput] = useState("");
  const [subjectNameInput, setSubjectNameInput] = useState("");
  const [saveTempIsLoading, setSaveTempIsLoading] = useState(false);

  const [isSaveTemplateModalVisible, setIsSaveTemplateModalVisible] =
    useState(false);

  const [clickedFieldName, setClickedFieldName] = useState("");

  const [editTemplateModal, setEditTemplateModal] = useState(true);

  const [activeState, setActiveState] = useState({});
  const [emptyDynField, setEmptyDynField] = useState(false);
  const [dynamicFieldArray, setDynamicFieldArray] = useState<any>([]);

  const [newlyAddedVariable, setNewlyAddedVariable] = useState({
    fieldName: "",
    fieldDescription: "",
    dataType: "",
  });

  const [variables, setVariables] = useState<string[]>([]);
  const [dataTypes, setDataTypes] = useState<string[]>([]);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [isModalVisibleSubject, setIsModalVisibleSubject] = useState(false);
  const [isAddNewVariableModalOKDisabled, setIsAddNewVariableModalOKDisabled] =
    useState<string>("");
  const [isAddModalVisibleSubject, setIsAddModalVisibleSubject] = useState(false);
  const [isAddNewVariableModalOKDisabledSubject, setIsAddNewVariableModalOKDisabledSubject] = useState<string>("");
  const [newlyAddedVariableSubject, setNewlyAddedVariableSubject] = useState({
    fieldName: "",
    fieldDescription: "",
    dataType: "",
  });
  const [clickedFieldNameSubject, setClickedFieldNameSubject] = useState("");
  const [activeStateSubject, setActiveStateSubject] = useState({});
  const [isAnyVariableClickedSubject, setIsAnyVariableClickedSubject] = useState(false);
 
  const [cursorPosition, setCursorPosition] = useState<any>();
    const key = 'updatable';

  // to display warning even after the page is refreshed
  if (!isOnline) {
    warning(
      "No Internet Connection",
      "It seems you have lost your internet connection...Please fix and retry!",
      "OK",
      false,
      false
    );
  } else {
    Modal.destroyAll();
  }

  const { fetchData } = useTriggerAPI();

  let createTemplateObject = {
    data: {
      template_id: "",
      notification_channel: "EMAIL",
      template_variables: {},
      template: {},
      request_type: "create",
      subject: subjectNameInput,
      raw_html: "",
      version: 1,
      sourceCode:"Pre_define"
    },
  };

  
  const addVariable = () => {
    setIsModalVisibleSubject(true);
  };
  
  const addVariableOkSubject =() => {
    let textBeforeCursorPosition = subjectNameInput.substring(0, cursorPosition)
    let textAfterCursorPosition = subjectNameInput.substring(cursorPosition, subjectNameInput.length)
    setIsModalVisibleSubject(false);
    setSubjectNameInput(textBeforeCursorPosition + window.localStorage.getItem("code") + textAfterCursorPosition)
 
  };

  const handleCancelSubject = () => {
    setIsModalVisibleSubject(false);
  };

  const addNewVariableSubject = () => {
    setIsAddModalVisibleSubject(true);
  };
  const openModalSubject = (clickedFieldIndex: string) => {
    setClickedFieldNameSubject(clickedFieldIndex);
    setActiveStateSubject({
      [clickedFieldIndex]: window.localStorage.getItem("code"),
    });
    setIsModalVisibleSubject(true);
  };

  const handleCancelAddSubject = () => {
    newlyAddedVariableSubject.dataType = "string";
    newlyAddedVariableSubject.fieldName = "";
    newlyAddedVariableSubject.fieldDescription = "";
    setIsAddModalVisibleSubject(false);
  };

  const handleNewVariableInputChangeSubject = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    const name = target.name;
    const value = target.value;
    let temp = "";
    value.trim() ? (temp = "a") : (temp = "");
    setIsAddNewVariableModalOKDisabledSubject(temp.trim())
    setNewlyAddedVariableSubject({ ...newlyAddedVariableSubject, [name]: value });
  };

  const onRadioChangeSubject = (e) => {
    window.localStorage.setItem("code", e.target.value.fieldCode);
    window.localStorage.setItem("type", e.target.value.dataType);
    setIsAnyVariableClickedSubject(true);
    setActiveStateSubject({ [clickedFieldNameSubject]: e.target.value.fieldCode });
  };


  
  const handleOkAddSubject = () => {
    let newVar = {
      id: fields.length + 1,
      ...newlyAddedVariableSubject,
      fieldCode: `{{${newlyAddedVariableSubject.fieldName.toLowerCase()}}}`,
    };
    let flag = 0;
    if (newlyAddedVariableSubject.fieldName) {
      INITIAL_FIELDS.map((fields) => {
        if (
          fields.fieldName.toLowerCase() ===
          newlyAddedVariableSubject.fieldName.toLowerCase()
        ) {
          flag = 1;
        }
      });
      if (flag === 0) {
        INITIAL_FIELDS.push(newVar);

        //refresh the input fields
        setIsAddModalVisibleSubject(false);
        newlyAddedVariableSubject.dataType = "string";
        newlyAddedVariableSubject.fieldName = "";
        newlyAddedVariableSubject.fieldDescription = "";
      } else {
        warning(
          CONSTANTS.DUPLICATE_VARIABLE_TITLE,
          CONSTANTS.DUPLICATE_VARIABLE_MESSAGE,
          "OK",
          true,
          false
        );
      }
    }
  };


  const handleNewVariableInputChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    const name = target.name;
    const value = target.value;
    let temp = "";
    value.trim() ? (temp = "a") : (temp = "");
    setIsAddNewVariableModalOKDisabled(temp.trim())
    setNewlyAddedVariable({ ...newlyAddedVariable, [name]: value });
  };

  const onRadioChange = (e) => {
    window.localStorage.setItem("code", e.target.value.fieldCode);
    window.localStorage.setItem("type", e.target.value.dataType);
    setIsAnyVariableClicked(true);
    setActiveState({ [clickedFieldName]: e.target.value.fieldCode });
  };

  const handleEditTemplateModalOk = () => {
    setEditTemplateModal(false);
    addButtonToHTML();
  };

  const handleOk = () => {
    let currentSelectedCode: any = window.localStorage.getItem("code");
    setVariables([...variables, currentSelectedCode]);
    let currentSelectedType: any = window.localStorage.getItem("type");
    setDataTypes([...dataTypes, currentSelectedType]);
    setIsModalVisible(false);
    addButtonToHTML();
  };

  const handleOkAdd = () => {
    let newVar = {
      id: fields.length + 1,
      ...newlyAddedVariable,
      fieldCode: `{{${newlyAddedVariable.fieldName.toLowerCase()}}}`,
    };
    let flag = 0;
    if (newlyAddedVariable.fieldName) {
      INITIAL_FIELDS.map((fields) => {
        if (
          fields.fieldName.toLowerCase() ===
          newlyAddedVariable.fieldName.toLowerCase()
        ) {
          flag = 1;
        }
      });
      if (flag === 0) {
        INITIAL_FIELDS.push(newVar);

        //refresh the input fields
        setIsAddModalVisible(false);
        newlyAddedVariable.dataType = "string";
        newlyAddedVariable.fieldName = "";
        newlyAddedVariable.fieldDescription = "";
      } else {
        warning(
          CONSTANTS.DUPLICATE_VARIABLE_TITLE,
          CONSTANTS.DUPLICATE_VARIABLE_MESSAGE,
          "OK",
          true,
          false
        );
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelAdd = () => {
    newlyAddedVariable.dataType = "string";
    newlyAddedVariable.fieldName = "";
    newlyAddedVariable.fieldDescription = "";
    setIsAddModalVisible(false);
  };

  function disableDrag(e: any) {
    e.preventDefault();
    e.dataTransfer.dropEffect = "none";
  }

  let addButtonToHTML = () => {
    let doc = document.getElementById("master") as HTMLDivElement;
    let mytag = doc.getElementsByClassName("myTag");

    for (let i = 0; i < mytag.length; i++) {
      let tag = mytag[i] as HTMLDivElement;

      if (tag.firstElementChild?.tagName === undefined) {
        let button = document.createElement("button");
        button.textContent = "Click to add variable";
        button.addEventListener("click", () => {
          openModal(i.toString());
        });
        let div = document.createElement("div");
        div.append(button);
        tag.append(div);
      } else if (i.toString() === Object.keys(activeState)[0]) {

        if (
          tag.firstElementChild?.tagName === "BUTTON" ||
          tag.firstElementChild.tagName === "DIV" ||
          tag.firstElementChild.tagName === "P"
        ) {
          let p = document.createElement("p");
          p.innerText = activeState[i.toString()];
          let div = document.createElement("div");
          p.style.cursor = "pointer";
          p.addEventListener("click", () => {
            openModal(i.toString());
          });
          div.append(p);
          tag.firstElementChild.replaceWith(div);
        }
      }

      let elements = [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "p",
        "strong",
        "span",
      ];
      for (let index = 0; index < elements.length; index++) {
        const tags = doc.getElementsByTagName(elements[index]);
        for (let tagIndex = 0; tagIndex < tags.length; tagIndex++) {
          const elemTag = tags[tagIndex] as HTMLElement;

          if (isLetter(elemTag.textContent?.trim().charAt(0))) {
            elemTag.contentEditable = "true";
            elemTag.addEventListener("dragenter", disableDrag);
            elemTag.addEventListener("dragover", disableDrag);
          }

          const observer = new MutationObserver((mutRecord) => {
            // console.log(mutRecord[0].target);
            if (mutRecord[0].target) {
              setIsTemplateSaved(true);
            }
          });
          observer.observe(elemTag, { characterData: true, subtree: true });
        }
      }
    }
  };

  function isLetter(str) {
    // return str.length === 1 && str.match(/[a-z]/i);

    return str.toLowerCase() !== str.toUpperCase();
  }

  const addNewVariable = () => {
    setIsAddModalVisible(true);
  };
  const openModal = (clickedFieldIndex: string) => {
    setClickedFieldName(clickedFieldIndex);
    setActiveState({
      [clickedFieldIndex]: window.localStorage.getItem("code"),
    });
    setIsModalVisible(true);
  };

  function removeDuplicates(arr: string[]) {
    return Array.from(new Set(arr));
  }

  function saveTemplate() {
    message.config({ maxCount: 0, duration: 1 });

    let doc = document.getElementById("master");
    let dyFields = doc?.getElementsByClassName("dyField") ?? [];
    let flag = 0;
    for (let k = 0; k < dyFields.length; k++) {
      const dyElem = dyFields[k];
      if (dyElem.nextElementSibling?.textContent?.trim().length === 0) {
        flag = 1;
      }
    }

    if (flag) {
      message.warning({ content: "Field names should not be empty!", key });
    } else {
      setIsSaveTemplateModalVisible(true);
    }
  }

  const removeBtnsFromHTML = () => {
    let doc = document.getElementById("master") as HTMLDivElement;
    let mytags = doc.getElementsByClassName("myTag");
    for (let index = 0; index < mytags.length; index++) {
      let tag = mytags[index] as HTMLDivElement;

      if (tag.firstElementChild?.firstElementChild?.tagName === "BUTTON") {
        
        tag.removeChild(tag.firstElementChild);
      }
      
      // if(tag.firstElementChild?.tagName === 'DIV'){
      //   let ptag = tag.firstElementChild.firstElementChild as HTMLParagraphElement;
      //   ptag.style.cursor = 'default';
      // }
      
    }

    let elements = ["h1", "h2", "h3", "h4", "h5", "h6", "p"];
    for (let index = 0; index < elements.length; index++) {
      const tags = doc.getElementsByTagName(elements[index]);
      for (let tagIndex = 0; tagIndex < tags.length; tagIndex++) {
        const elemTag = tags[tagIndex] as HTMLElement;
        elemTag.contentEditable = "false";
      }
    }
  };

  function handleSaveTemplateOk() {
    message.config({ maxCount: 0, duration: 1 });

    // to prevent user from entering emojis inside input.
    const regex_emoji =
      /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;
    if (regex_emoji.test(tempNameInput) || regex_emoji.test(subjectNameInput)) {
      message.warning({ content:"Emojis are not allowed!", key});
    } else {
      saveTemplateToDB();
      
    }
  }

  async function saveTemplateToDB() {
    axiosRetry(axios, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 2000;
      },
    });

    message.config({ maxCount: 0, duration: 1 });
    setIsTempNameInputDisabled(true);
    setSaveTempIsLoading(true);

    removeBtnsFromHTML();
    let mDoc = document.getElementById("master");

    let templateVariables: any = [];
    removeDuplicates(variables).map((variable, index) => {
      let temp: any = {};

      temp["name"] = variable.substring(2, variable.length - 2);
      temp["type"] = dataTypes[index];
      templateVariables.push(temp);
    });

    createTemplateObject["data"]["template_id"] = tempNameInput.trim();
    createTemplateObject["data"]["template_variables"] =
      JSON.stringify(templateVariables);
    createTemplateObject["data"]["template"] = mDoc?.outerHTML || {};

    try {
      const data = await fetchData(createTemplateObject);
      if (data.status === 201) {
        setSaveTempIsLoading(false);
        success();
      } 
    } catch (error:any) {
      setSaveTempIsLoading(false);
      if (error.response.status === 409) {
        message.error(CONSTANTS.SAME_TEMPLATE_NAME, 2);
        setIsTempNameInputDisabled(false);
        setSaveTempIsLoading(false);
      } else{
      message.error({content:CONSTANTS.ERROR_IN_CREATING, key});
      }
      setSaveTempIsLoading(false);
      setIsTempNameInputDisabled(false);
    }
  }

  function handleSaveTemplateOnChange(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    setTempNameInput(target.value);
  }

  function handleSuccessOK() {
    setIsSaveTemplateModalVisible(false);
    window.location.href = `${window.location.origin}/home`;
  }

  function handleHomeButtonClick() {
    //if template is not saved and a variable is applied to any field
    // then the warning should appear
    if (isTemplateSaved || Object.keys(activeState).length > 0) {
      warning(
        "Save Template",
        "Do you want to Exit to homepage or Continue ?",
        "Continue",
        true,
        true
      );
    } else {
      window.location.href = `${window.location.origin}/home`;
    }
  }

  function success() {
    Modal.success({
      content: `${tempNameInput} has been Saved!`,
      onOk: handleSuccessOK,
      // onCancel :handleSuccessCancel,
      okText: "Back to Home",
      keyboard: false,
    });
  }

  function goToHomePage() {
    nav("/home");
  }

  function warning(
    title: string,
    content: string,
    okText: string,
    okBtnVisible: boolean,
    cancelBtnVisible: boolean
  ) {
    Modal.confirm({
      title: title,
      content: content,
      okButtonProps: {
        style: { visibility: `${okBtnVisible ? "visible" : "hidden"}` },
      },
      cancelButtonProps: {
        style: { visibility: `${cancelBtnVisible ? "visible" : "hidden"}` },
      },
      okText: okText,
      cancelText: "Exit to homepage",
      closable: false,
      keyboard: false,
      onCancel: goToHomePage,
    });
  }
  function handleEnterPress(e) {
    if (e.key === "Enter") {
      if (tempNameInput.trim() && subjectNameInput.trim()) {
        handleSaveTemplateOk();
      }
    }
  }

  function handleInternetChange(isOnline_) {
    setIsOnline(isOnline_);
  }

  return (
    <>
      <MyHeader
        pageTitle="Create Template"
        homebtnVisible
        handleParentClick={handleHomeButtonClick}
      />
      <Modal
        title="Create Template"
        visible={editTemplateModal}
        closable={false}
        footer={[
          <Button
            key="back"
            onClick={() => {
              window.location.href = `${window.location.origin}/home`;
            }}
          >
            Back to homepage
          </Button>,
          <Button type="primary" key="edit" onClick={handleEditTemplateModalOk}>
            Start
          </Button>,
           
        ]}
        width="500px"
      >
        <h2 className="editTempH3">Click to start creating your template</h2>
      </Modal>
      <div className="backBtnContainer">
        <div></div>
      </div>

      <Modal
        title="Select a Variable to add"
        visible={isModalVisible}
        onOk={handleOk}
        closable={false}
        style={{ top: 10 }}
        mask
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
          <Button
            key="addField"
            type="primary"
            onClick={handleOk}
            // onClick={addVariableOk}
            disabled={!isAnyVariableClicked}
          >
            Select Variable
          </Button>,
          <Button type="primary" onClick={addNewVariable}>
            Add New Variable
          </Button>,
        ]}
      >
        <Radio.Group onChange={onRadioChange}>
          {fields.map((field) => {
            return (
              <div style={{ display: "block" }}>
                <Radio value={field} name={field.fieldName}>
                  <div>
                    <h4>{field.fieldName}</h4>
                  </div>
                </Radio>
              </div>
            );
          })}
        </Radio.Group>
      </Modal>

      <Detector
        onChange={(isOnline) => handleInternetChange(isOnline)}
        // render method is necessary
        render={({ online }) => <div></div>}
      />

      <div className="templateContainer">
        <div id="master" dangerouslySetInnerHTML={{ __html: html }} />
        <Button
          type="primary"
          size="large"
          onClick={saveTemplate}
          icon={<SaveOutlined />}
          className="saveTempBtn"
        >
          Save as new Template
        </Button>
      </div>

      {/* Add new Variable Modal */}
      <div className="modContainer">
        <Modal
          title="Add New Variable"
          visible={isAddModalVisible}
          onOk={handleOkAdd}
          okButtonProps = {{disabled: !isAddNewVariableModalOKDisabled}}
          onCancel={handleCancelAdd}
          width="250px"
          style={{ borderRadius: "10px" }}
        >
          <Space direction="vertical">
            <Input
              id="input"
              placeholder="Field Name"
              name="fieldName"
              value={newlyAddedVariable.fieldName}
              onChange={handleNewVariableInputChange}
            />
            <Input
              id="input"
              placeholder="Field Description"
              name="fieldDescription"
              value={newlyAddedVariable.fieldDescription}
              onChange={handleNewVariableInputChange}
            />

            <Input.Group compact>
              <div className="addbutton">
                <h5>Select Data type :</h5>
              </div>
              <Select
                style={{ width: "100%", borderRadius: "10px" }}
                defaultValue="string"
                value={newlyAddedVariable.dataType}
                onChange={(value) =>
                  setNewlyAddedVariable({
                    ...newlyAddedVariable,
                    dataType: value,
                  })
                }
              >
                <Option value="string">String </Option>
                <Option value="number">Number</Option>
                <Option value="boolean">Boolean</Option>
                <Option value="date">Date</Option>
              </Select>
            </Input.Group>
          </Space>
        </Modal>
      </div>
      
      <Modal
        title="Save Template as"
        visible={isSaveTemplateModalVisible}
        // onOk={handleSaveTemplateOk}
        closable={false}
        // okButtonProps={{
        //   disabled: !tempNameInput.trim() || !subjectNameInput.trim(),
        //   loading: saveTempIsLoading,
        // }}
        // cancelButtonProps={{ disabled: saveTempIsLoading }}
        onCancel={() => {
          setIsSaveTemplateModalVisible(false);
          setTempNameInput("");
        }}
        width="400px"
        footer={[
          <Tooltip title="Add a Variable">
          <Button onClick={addVariable}
          disabled= {!tempNameInput.trim() || !subjectNameInput.trim()}
          >
           Add variable
          </Button>
          </Tooltip>,
          <Button
            key="back"
            onClick={() => {
              setIsSaveTemplateModalVisible(false);
              setTempNameInput("");
            }}
            disabled={saveTempIsLoading}
          >
            Cancel
          </Button>,
          <Button type="primary" key="edit" onClick={handleSaveTemplateOk} 
          disabled= {!tempNameInput.trim() || !subjectNameInput.trim()}
          loading= {saveTempIsLoading}
          >
            Ok
          </Button>, 
        ]}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Input
            placeholder="Template Name"
            name="tempName"
            id="input"
            value={tempNameInput}
            width="100%"
            onKeyDown={handleEnterPress}
            disabled={isTempNameInputDisabled}
            onChange={handleSaveTemplateOnChange}
          />

          <Input
            placeholder="Email Subject (Required)"
            name="tempName"
            id="input"
            onKeyDown={handleEnterPress}
            value={subjectNameInput}
            width="100%"
            onChange={(e) => {
              setSubjectNameInput(e.target.value);
              setCursorPosition(e.target.selectionStart)
            }}
          />
        </Space>
      </Modal>

      {/* Add new Variable Modal */}
      <div className="modContainer">
        <Modal
          title="Add New Variable"
          visible={isAddModalVisibleSubject}
          onOk={handleOkAddSubject}
          okButtonProps = {{disabled: !isAddNewVariableModalOKDisabledSubject}}
          onCancel={handleCancelAddSubject}
          width="250px"
          style={{ borderRadius: "10px" }}
        >
          <Space direction="vertical">
            <Input
              id="input"
              placeholder="Field Name"
              name="fieldName"
              value={newlyAddedVariableSubject.fieldName}
              onChange={handleNewVariableInputChangeSubject}
            />
            <Input
              id="input"
              placeholder="Field Description"
              name="fieldDescription"
              value={newlyAddedVariableSubject.fieldDescription}
              onChange={handleNewVariableInputChangeSubject}
            />

            <Input.Group compact>
              <div className="addbutton">
                <h5>Select Data type :</h5>
              </div>
              <Select
                style={{ width: "100%", borderRadius: "10px" }}
                defaultValue="string"
                value={newlyAddedVariableSubject.dataType}
                onChange={(value) =>
                  setNewlyAddedVariableSubject({
                    ...newlyAddedVariableSubject,
                    dataType: value,
                  })
                }
              >
                <Option value="string">String </Option>
                <Option value="number">Number</Option>
                <Option value="boolean">Boolean</Option>
                <Option value="date">Date</Option>
              </Select>
            </Input.Group>
          </Space>
        </Modal>
      </div>
      <Modal
        title="Select a Variable to add"
        visible={isModalVisibleSubject}
        onOk={addVariableOkSubject}
        closable={false}
        style={{ top: 10 }}
        mask
        footer={[
          <Button key="back" onClick={handleCancelSubject}>
            Close
          </Button>,
          <Button
            key="addField"
            type="primary"
            onClick={addVariableOkSubject}
            disabled={!isAnyVariableClickedSubject}
          >
            Select Variable
          </Button>,
          <Button type="primary" onClick={addNewVariableSubject}>
            Add New Variable
          </Button>,
        ]}
      >
        <Radio.Group onChange={onRadioChangeSubject}>
          {fields.map((field) => {
            return (
              <div style={{ display: "block" }}>
                <Radio value={field} name={field.fieldName}>
                  <div>
                    <h4>{field.fieldName}</h4>
                  </div>
                </Radio>
              </div>
            );
          })}
        </Radio.Group>
      </Modal>
      
    </>
  );

}
