import 'antd/dist/antd.min.css'
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import HomePage from "./Pages/HomePage";
import ViewTemplate from "./Pages/ViewTemplate";
import CreateFromWizard from "./Pages/CreateFromWizard";
import SelectTemplateOption from "./Pages/SelectTemplateOption";
import DeleteTemplate from "./Pages/DeleteTemplate";
import UpdateTemplate from "./Pages/UpdateTemplate";
import CreateTemplateFromSourceCode from "./Pages/CreateTemplateFromSourceCode";
import CreateFromExisting from "./Pages/CreateFromExisting";
import SMSTemplate from "./Pages/SMSTemplate";

const App = () => (
  <>
  {/* <div className="message">Website currently works only on Desktop view</div> */}
    <div className="App">
      <Router>
        <Routes>
          <Route>``
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/deletetemplate" element={<DeleteTemplate />} />
            <Route path="/updatetemplate" element={<UpdateTemplate />} />
            <Route path="/createtemplate" element={<CreateFromExisting/>} />
            <Route path="/wizard" element={<CreateFromWizard />} />
            <Route path="/sms" element={<SMSTemplate/>} />
            <Route path="/source" element={<CreateTemplateFromSourceCode />} />
            <Route path="/options" element={<SelectTemplateOption />} />
            <Route path="/viewtemplate" element={<ViewTemplate />} />
          </Route>
        </Routes>
      </Router>
    </div>

  </>
);

export default App;
